
import ClubJoinCodeForm from '~/components/organisms/clubs/ClubJoinCodeForm.vue'
import ClubProfile from '~/components/molecules/clubs/ClubProfile.vue'
import { getMessageFromErrorResponse } from '~/utils/data'

export default {
  components: { ClubJoinCodeForm, ClubProfile },

  data() {
    return {
      club: null,
      codeValue: null,
    }
  },

  computed: {
    open: {
      get() {
        return this.$store.getters['clubs/isDialogJoinClubOpen']
      },
      set(val) {
        this.$store.commit('clubs/setIsDialogJoinClubOpen', val)
      },
    },
  },

  watch: {
    /**
     * Clear club.
     * Clear form when dialog is not open.
     * @param {Boolean} val the dialog state.
     */
    open(val) {
      this.club = null
      if (val) {
        this.codeValue = null
      }
    },
  },

  methods: {
    setClub(values) {
      this.club = values
    },

    setCodeAndClearClub() {
      this.codeValue = this.club.code
      this.club = null
    },

    joinClubSelected() {
      if (this.$auth.user) {
        this.requestJoinClub()
      } else {
        this.open = false
        this.$store.commit('clubs/setClubToJoin', this.club)
        this.$router.push({ name: 'signup' })
      }
    },

    /**
     * Send a request to join a club.
     */
    async requestJoinClub() {
      this.loading = true
      try {
        const result = await this.$store.dispatch('clubs/joinClub', {
          clubId: this.club.id,
          playerId: this.$auth.user.id,
        })

        this.club.club_grant_admin_request = result

        /**
         * Store the new club.
         * Don't fetch it from the server because the user admin rights are not yet validated.
         */
        this.$store.commit('clubs/addClubToList', this.club)
        this.$store.commit('clubs/setSelected', this.club)

        this.$router.push({
          name: 'clubs-club_id',
          params: { club_id: this.club.id },
        })
        this.$notify({
          message: this.$tc('notification.club.join', 1, [this.club.name]),
          type: 'success',
        })
      } catch (error) {
        const message = getMessageFromErrorResponse(error, {
          409: 'error.club.cannot_resend_request',
        })

        this.$notify({ message, type: 'error' })
      } finally {
        this.loading = false
        this.open = false
      }
    },
  },
}
