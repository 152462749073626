
import PaymentClubBadge from '~/components/molecules/header/PaymentClubBadge.vue'

export default {
  components: { PaymentClubBadge },

  computed: {
    paymentClub() {
      return this.$store.getters['clubs/getPaymentClub']
    },
  },
}
