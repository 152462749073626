export default ({ $auth, $config, $sentry }) => {
  if ($config.sentry.enabled) {
    const user = $auth.user
    $sentry.setUser(
      $auth.loggedIn && user
        ? {
            id: user.id,
            username: `${user.first_name} ${user.last_name}`.trim(),
            email: user.email,
          }
        : null
    )
  }
}
