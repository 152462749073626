
export default {
  props: {
    normalizeContentPadding: {
      /**
       * By default, v-card-text has padding-bottom but no padding-top.
       * Using this option will add padding-top.
       */
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: this.value,
    }
  },

  watch: {
    value(val) {
      this.open = val
    },

    open(val) {
      if (val !== this.value) {
        this.$emit('input', val)
      }
    },
  },
}
