
export default {
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
    }
  },

  methods: {
    handleConfirm() {
      this.$router.push({ name: 'logout' })
    },
  },
}
