import { nextTick } from 'vue'
import { ADMIN_STATUS } from '~/models/enums'

/**
 * Handle default redirections.
 */
export default function ({ $auth, route, redirect, store }) {
  if (!route.name) {
    // if no route name, it means route is unwnkown and will fallback to 404 page
    return
  }

  if (!$auth.user) {
    if (route.name === 'login' || route.name === 'signup') {
      nextTick(() => store.commit('clubs/resetState'))
    }
    if (route.name === 'login') {
      nextTick(() => store.commit('clubs/setClubToJoin'))
    }
    return
  }

  if (route.name === 'index') {
    return redirect({
      name: 'clubs',
    })
  }

  if (route.name.startsWith('clubs-club_id')) {
    const club = store.getters['clubs/getSelected']
    if (!club) {
      return
    }
    if (route.name === 'clubs-club_id') {
      // this page is accessible just after creating the club, only once
      if (!club.created) {
        return redirect({
          name: 'clubs-club_id-posts',
          params: { ...route.params },
        })
      }
    }
    if (route.name === 'clubs-club_id-approval-pending') {
      if (club.membership?.admin_status === ADMIN_STATUS.APPROVED) {
        return redirect({
          name: 'clubs-club_id',
          params: { ...route.params },
        })
      }
    } else if (
      club.membership?.admin_status !== ADMIN_STATUS.APPROVED &&
      route.name !== 'clubs-club_id-messaging'
    ) {
      return redirect({
        name: 'clubs-club_id-approval-pending',
        params: { ...route.params },
      })
    }
  }
}
