
import TInput from '~/components/atoms/TInput.vue'

export default {
  components: { TInput },

  props: {
    prependIcon: {
      type: String,
      default: null,
    },
  },

  methods: {
    handleClick() {
      this.$nextTick(() => this.$refs.fileInput.$refs.input.click())
    },
  },
}
