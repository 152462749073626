import { sortBy } from 'lodash'
import { request } from '~/utils/request'

export const actions = {
  async fetchList(_, { sportId }) {
    const { data } = await request(this).get('/sports/{sportId}/categories', {
      sportId,
    })
    return sortBy(data, 'position')
  },
}
