/**
 * The goal of this plugin is to use pluralisation of Phrase service.
 * Phrase splits translations in several keys.
 *
 * This plugin automatically looks for the correct pluralisation form regarding the count.
 *
 * @example
 * ```js
 * // nuxt-i18n
 * {
 *   apple: 'no apples | one apple | {count} apples'
 * }
 *
 * $tc('apple', 3);
 *
 * // Phrase
 * {
 *   apple: {
 *     zero: 'no apples',
 *     one: 'one apple,
 *     other: '{count} apples'
 *   }
 * }
 *
 * $tcph('apple', 3); // will use `apple.other` as key
 * ```
 */
export default function ({ i18n }, inject) {
  inject(
    'tcph',
    /**
     * Use the correct pluralized key to use the Phrase service.
     * @param {String} key the translation key.
     * @param {Number} count the count.
     * @param {Array|Object} params tokens to replace in translated text.
     */
    (key, count = 1, params) => {
      if (!key || !key.startsWith('phrase.')) {
        return i18n.tc(key, count, params)
      }

      // check if a translation exists
      const translations = i18n.te(key) ? i18n.t(key) : null
      if (!translations || typeof translations !== 'object') {
        return i18n.tc(key, count, params)
      }

      let potentialNestedKeys = []

      if (count > 1) {
        potentialNestedKeys = ['other', 'one']
      } else if (count === 0) {
        potentialNestedKeys = ['zero', 'other']
      } else {
        potentialNestedKeys = ['one', 'other']
      }

      const nestedKey = potentialNestedKeys.find(
        (potentialKey) => translations[potentialKey]
      )

      if (nestedKey) {
        return i18n.tc(`${key}.${nestedKey}`, count, params)
      } else {
        return i18n.tc(`${key}.${potentialNestedKeys[0]}`, count, params)
      }
    }
  )
}
