import { EVENT_TYPE } from '~/models/enums'

export default function ({ app, i18n }, inject) {
  /**
   * Get the event title, according to the event type.
   * @param {Object} event the event.
   * @param {Number} type the event type.
   * @returns {String} the event name.
   */
  const getEventTitle = (event, type) => {
    if (!type) {
      type = event.event_type
    }

    return type === EVENT_TYPE.MATCH
      ? getMatchTitle(event)
      : event.training_session?.title || event.event_title || event.title
  }

  /**
   * Get the match title.
   * @param {Object} match the match.
   * @returns {String}
   */
  const getMatchTitle = (match) => {
    const name =
      typeof match === 'object'
        ? match.opponent_name || match.event_title || match.title
        : match
    return i18n.t('page.event.match_title', { name })
  }

  /**
   * Update locale used in the application.
   * @param {String} locale the locale code.
   */
  const setLocale = (locale) => {
    i18n.setLocale(locale)

    if (app && app.$dayjs) {
      // use `$dayjs` from `app` to not depend of plugin mounting order
      app.$dayjs.locale(locale)
    }
  }

  inject('i18nHelper', {
    getEventTitle,
    getMatchTitle,
    setLocale,
  })
}
