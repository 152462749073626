
import { buildPictureUrl } from '~/utils/data'

export default {
  props: {
    club: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipPosition: {
      type: String,
      default: 'bottom',
    },
  },

  computed: {
    clubPicUrl() {
      return buildPictureUrl(this.club.club_pic_url, this.club.updated_at)
    },

    srcImage() {
      return this.club?.is_club_pic_uploaded
        ? this.clubPicUrl
        : '/svg/default-profile-club-border.svg'
    },
  },
}
