
const INITIAL_COUNTDOWN = 60 // in seconds

export default {
  data() {
    return {
      interval: undefined,
      countdown: INITIAL_COUNTDOWN,
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    open: {
      get() {
        return this.$store.getters['app/isBuildOutdated']
      },
      set(val) {
        this.$store.commit('app/setBuildOutdated', val)
      },
    },
  },

  watch: {
    /**
     * Reload page when countdown is over.
     */
    countdown(val) {
      if (val <= 1) {
        this.reload()
      }
    },

    /**
     * Start the timeout when the snackbar opens.
     * Reset the countdown when the snackbar closes.
     */
    open: {
      immediate: true,
      handler(val) {
        if (val) {
          this.startTimeout()
        } else {
          this.countdown = INITIAL_COUNTDOWN
        }
      },
    },
  },

  mounted() {
    // launch timer of $build plugin
    if (this.$config.build.commit_id) {
      this.$build.check() // check at mount
      this.$build.launchTimer() // launch periodic check
    }
  },

  beforeDestroy() {
    // cancel timer of $build plugin
    if (this.$config.build.commit_id) {
      this.$build.stopTimer()
      this.cancelTimeout()
    }
  },

  methods: {
    /**
     * Stop the coundown.
     */
    cancelTimeout() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
      this.open = false
    },

    /**
     * Launch the countdown.
     */
    startTimeout() {
      this.interval = setInterval(() => {
        this.countdown = Math.max(0, this.countdown - 1)
      }, 1000)
    },

    /**
     * Reload the page.
     */
    reload() {
      window.location.reload()
    },
  },
}
