import dayjs from 'dayjs'
import de from 'dayjs/locale/de'
import 'dayjs/locale/en' // load on demand
import es from 'dayjs/locale/es'
import fr from 'dayjs/locale/fr'
import it from 'dayjs/locale/it'
import pt from 'dayjs/locale/pt'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

export default function ({ store }, inject) {
  // initialize with the current lang
  dayjs.locale(store.$i18n.locale)
  dayjs.extend(duration)
  dayjs.extend(localeData)
  dayjs.extend(localizedFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(timezone)
  dayjs.extend(updateLocale)
  dayjs.extend(utc)
  dayjs.extend(isBetween)

  /**
   * Override short months for french.
   * Override separator between date and hour for long date formats.
   *
   * Don't use `updateLocale()` because this method only works if the locale has been loaded once.
   * Initializing a locale different than the current one doesn't override formats.
   *
   * @see https://github.com/iamkun/dayjs/blob/dev/src/locale/fr.js
   * @see https://github.com/iamkun/dayjs/blob/dev/src/locale/de.js
   * @see https://github.com/iamkun/dayjs/blob/dev/src/locale/en.js
   * @see https://github.com/iamkun/dayjs/blob/dev/src/locale/es.js
   * @see https://github.com/iamkun/dayjs/blob/dev/src/locale/it.js
   * @see https://github.com/iamkun/dayjs/blob/dev/src/locale/pt.js
   */

  fr.formats.LLL = 'D MMMM YYYY - HH:mm' // add time separator
  fr.formats.LLLL = 'dddd D MMMM YYYY - HH:mm' // add time separator
  fr.formats.monthsShort =
    'jan._fév._mar_avr._mai_juin_juil._aoû_sep._oct._nov._déc.'.split('_')
  fr.formats.custom_short_day = 'D MMM'

  de.formats.LLL = 'D. MMMM YYYY - HH:mm' // add time separator
  de.formats.LLLL = 'dddd, D. MMMM YYYY - HH:mm' // add time separator
  de.formats.custom_short_day = 'D. MMM'

  // en.formats.LLL = 'MMMM D, YYYY - h:mm A' // add time separator
  // en.formats.LLLL = 'dddd, MMMM D, YYYY - h:mm A' // add time separator
  // en.formats.lll = 'MMM D, YYYY - h:mm A' // add time separator
  // en.formats.llll = 'ddd, MMM D, YYYY - h:mm A' // add time separator
  // en.formats.custom_short_day = 'MMM D'

  // updateLocale can be used because it's the default lang in the lib
  dayjs.updateLocale('en', {
    formats: {
      // abbreviated format options allowing localization
      LTS: 'h:mm:ss A',
      LT: 'h:mm A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY - h:mm A',
      LLLL: 'dddd, MMMM D, YYYY - h:mm A',
      // lowercase/short, optional formats for localization
      l: 'M/D/YYYY',
      ll: 'MMM D, YYYY',
      lll: 'MMM D, YYYY - h:mm A', // add time separator
      llll: 'ddd, MMM D, YYYY - h:mm A', // add time separator
      custom_short_day: 'MMM D',
    },
  })

  es.formats.LLL = 'D [de] MMMM [de] YYYY - H:mm' // add time separator
  es.formats.LLLL = 'dddd, D [de] MMMM [de] YYYY - H:mm' // add time separator
  es.formats.custom_short_day = 'D [de] MMM'

  it.formats.LLL = 'D MMMM YYYY - HH:mm' // add time separator
  it.formats.LLLL = 'dddd D MMMM YYYY - HH:mm' // add time separator
  it.formats.custom_short_day = 'D MMM'

  // PT dates already have a separator with hours
  pt.formats.custom_short_day = 'D [de] MMMM'

  inject('dayjs', dayjs)
}
