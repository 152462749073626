import { request } from '~/utils/request'

export const state = () => {
  return {
    jerseySizes: [],
    shortSizes: [],
    shoeSizes: [],
  }
}

export const actions = {
  /**
   * Fetch all jersey sizes and memorize them.
   * @returns {Promise<Array>}
   */
  async fetchJerseySizes({ commit }) {
    const { data } = await request(this).get('/sport-kits/jersey-sizes')

    commit('setJerseySizes', data)
    return data
  },

  /**
   * Return all short sizes.
   * @returns {Promise<Array>}
   */
  async fetchShortSizes({ commit }) {
    const { data } = await request(this).get('/sport-kits/short-sizes')

    commit('setShortSizes', data)
    return data
  },

  /**
   * Return all shoe sizes.
   * @returns {Promise<Array>}
   */
  async fetchShoeSizes({ commit }) {
    const { data } = await request(this).get('/sport-kits/shoe-sizes')

    commit('setShoeSizes', data)
    return data
  },
}

export const mutations = {
  /**
   * Set or refresh list of jersey sizes.
   * @param {Object} state the store state.
   * @param {Array} sizes sizes the list of sizes to memorize.
   */
  setJerseySizes(state, sizes) {
    state.jerseySizes = sizes
  },

  /**
   * Set or refresh list of jersey sizes.
   * @param {Object} state the store state.
   * @param {Array} sizes sizes the list of sizes to memorize.
   */
  setShortSizes(state, sizes) {
    state.shortSizes = sizes
  },

  /**
   * Set or refresh list of jersey sizes.
   * @param {Object} state the store state.
   * @param {Array} sizes sizes the list of sizes to memorize.
   */
  setShoeSizes(state, sizes) {
    state.shoeSizes = sizes
  },
}
