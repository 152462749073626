
import { buildSourceLabelToken } from '~/utils/data'

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
  },

  computed: {
    /**
     * The label to display.
     * @returns {String}
     */
    translatedLabel() {
      const token = this.label || buildSourceLabelToken(this.source)

      if (!token) {
        return undefined
      }

      return this.$tc(token, 1)
    },
  },
}
