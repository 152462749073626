
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isDevelopmentEnv() {
      return this.$config.environment === 'development'
    },
  },

  layout: 'error_layout',
}
