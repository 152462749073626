
import GoogleTagManager from '~/components/atoms/GoogleTagManager.vue'
import AuthWelcome from '~/components/molecules/auth/AuthWelcome.vue'
import LoginTemplate from '~/components/templates/LoginTemplate.vue'

export default {
  components: { AuthWelcome, GoogleTagManager, LoginTemplate },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
