
import { buildSourceLabelToken } from '~/utils/data'

export default {
  props: {
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    label: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    source: {
      type: String,
      default: null,
    },
    value: {
      type: [Boolean, String, Number],
      default: undefined,
    },
  },

  computed: {
    /**
     * All the properties to forward to the input component.
     * Includes implicit attributes and explicit properties.
     * @returns {Object}
     */
    allProps() {
      const props = { ...this._props }
      delete props.label // label is in a slot

      return { ...this.$attrs, ...props }
    },
    allSlots() {
      const slots = { ...this.$slots }
      delete slots.label
      return slots
    },
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    /**
     * Whether if this field is required or not.
     * @returns {Boolean}
     */
    isRequired() {
      if (this.rules) {
        return this.rules.findIndex((rule) => rule.name === 'required') > -1
      }

      return this.required
    },
    /**
     * The label to display.
     * @returns {String}
     */
    translatedLabel() {
      const token = this.label || buildSourceLabelToken(this.source)

      if (!token) {
        return undefined
      }

      return this.$tc(token, 1)
    },
  },
}
