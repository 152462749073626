
export default {
  props: {
    to: {
      type: [Object, String],
      default: undefined,
    },
  },
  computed: {
    club() {
      return this.$store.getters['clubs/getSelected']
    },

    fallbackRoute() {
      if (this.club) {
        return {
          name: 'clubs-club_id',
          params: { ...this.$route.params },
        }
      }

      return { name: 'index', path: '/' }
    },

    route() {
      const route = this.$store.getters['app/getRouteFrom']

      return this.to || route || this.fallbackRoute
    },

    backTo() {
      return this.route
    },
  },

  /**
   * This component is a single wrapper to inject some properties.
   * No additional DOM element is rendered except child.
   */
  render() {
    return this.$scopedSlots.default({ to: this.backTo })
  },
}
