import { request } from '~/utils/request'

/**
 * Store state.
 * Format is:
 *
 * ```example
 *   {
 *     data: {
 *       1: {
 *         club_id: 1,
 *         club_legal_name: 'AS TEAMPULSE',
 *         club_kyc_level: 'LIGHT',
 *         payout_player_id: 99102,
 *         updated_at: '2023-07-06T14:36:42.456Z',
 *         online_payment_activated: true,
 *         club_online_payment_activated: true,
 *         club_online_payment_activated_for_guests: true,
 *         payoutPlayer: {
 *           first_name: 'Yvan',
 *           last_name: 'G.',
 *         },
 *         legal_address: {
 *           line_1: "Rue Laurent Charles",
 *           line_2: "",
 *           zipcode: "42400",
 *           city: "ST CHAMMOND",
 *           country: "FR",
 *           region: ""
 *         }
 *         legal_representative: {
 *           first_name: 'John',
 *           last_name: 'Duff',
 *           email: 'john.duff@company.com',
 *           birthday: -298909057, // yes, negative timestamp for dates before 1970-01-01
 *           nationality: 'GB',
 *           country_of_residence: 'FR',
 *         },
 *         bank_account: {
 *           iban: 'FR117452110000JIULYOYXT2D48',
 *           bic: 'MPAYFRP1PIN',
 *         },
 *         wallet: {
 *           balance: 1234.56,
 *           currency: 'EUR',
 *         },
 *       },
 *     },
 *     documents: {
 *       1: {
 *         identity_proof: null,
 *         registration_proof: null,
 *         articles_of_asociation: null,
 *       },
 *     },
 *     mandates: {
 *       1: {},
 *     },
 *     transactions: {
 *       1: [],
 *     },
 *   }
 * ```
 */

export const state = () => ({
  data: {},
  documents: {},
  mandates: {},
  transactions: {},
})

export const actions = {
  /**
   * Create a new mandate and memorize it.
   * @returns {Promise<Object>}
   */
  async createMandate({ commit }, { clubId }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/mangopay/mandate',
      { clubId }
    )

    commit('setMandate', { clubId, mandate: data })

    return data
  },

  async createPayout({ dispatch }, { amount, clubId, label = '' }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/mangopay/payouts',
      { clubId },
      { amount, label }
    )

    dispatch('fetchTransactions', { clubId })

    return data
  },

  /**
   * Fetch bank data.
   * @returns {Promise<Object>}
   */
  async fetchData({ commit }, { clubId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/mangopay',
      { clubId }
    )

    commit('setData', { clubId, values: data })

    return data
  },

  /**
   * Fetch documents for the bank account.
   * @returns {Promise<Object>}
   */
  async fetchDocuments({ commit }, { clubId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/mangopay/documents',
      { clubId }
    )

    commit('setDocuments', { clubId, documents: data })

    return data
  },

  /**
   * Fetch a club mandate.
   * @returns {Promise<Object>}
   */
  async fetchMandate({ commit }, { clubId }) {
    const { data, status } = await request(this, 'club').get(
      '/clubs/{clubId}/mangopay/mandate',
      { clubId }
    )

    commit('setMandate', { clubId, mandate: status === 200 ? data : null })

    return data
  },

  /**
   * Fetch the transaction list.
   * @returns {Promise<Array<Object>>}
   */
  async fetchTransactions({ commit }, { clubId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/mangopay/transactions',
      { clubId }
    )

    commit('setTransactions', { clubId, transactions: data.transactions || [] })

    return data
  },

  /**
   * Update anti-ads subscription and apply the new values in the state.
   * @returns {Promise<Object>}
   */
  async updateAntiAds({ commit, getters }, { clubId, values }) {
    const { data } = await request(this, 'club').put(
      '/clubs/{clubId}/mangopay/anti-ads',
      { clubId },
      values
    )

    const legalRepresentative = (getters.getData(clubId) || {})
      .legal_representative
    const legalAddress = (getters.getData(clubId) || {}).legal_address

    commit('mergeData', {
      clubId,
      values: {
        club_id: clubId,
        club_legal_name: values.club_legal_name,
        anti_ads_activated: values.club_anti_ads_activated,
        anti_ads_activated_for_guests:
          values.club_anti_ads_activated_for_guests,
        legal_address: {
          legalAddress,
          ...buildStoreLegalAddressFromFormPayload(values),
        },
        legal_representative: {
          ...legalRepresentative,
          first_name: values.legal_representative_first_name,
          last_name: values.legal_representative_last_name,
          email: values.legal_representative_email,
        },
      },
    })

    commit(
      'clubs/patchClub',
      {
        id: clubId,
        anti_ads_activated: values.club_anti_ads_activated,
        anti_ads_activated_for_guests:
          values.club_anti_ads_activated_for_guests,
        bank_account: {
          iban: values.club_iban,
          bic: values.club_bic,
        },
      },
      { root: true }
    )

    return data
  },

  /**
   * Update bank data and apply new values in the state.
   * @returns {Promise<Object>}
   */
  async updateOnlinePayment({ commit }, { clubId, values }) {
    const { data } = await request(this, 'club').put(
      '/clubs/{clubId}/mangopay/online-payment',
      { clubId },
      values
    )

    const legalAddress = (getters.getData(clubId) || {}).legal_address
    commit('mergeData', {
      clubId,
      values: {
        club_id: clubId,
        club_legal_name: values.club_legal_name,
        online_payment_activated: values.club_online_payment_activated,
        bank_account: {
          iban: values.club_iban,
          bic: values.club_bic,
        },
        legal_address: {
          legalAddress,
          ...buildStoreLegalAddressFromFormPayload(values),
        },
        legal_representative: {
          first_name: values.legal_representative_first_name,
          last_name: values.legal_representative_last_name,
          email: values.legal_representative_email,
          birthday: values.legal_representative_birthday,
          nationality: values.legal_representative_nationality,
          country_of_residence:
            values.legal_representative_country_of_residence,
        },
      },
    })

    commit(
      'clubs/patchClub',
      {
        id: clubId,
        online_payment_activated: values.club_online_payment_activated,
      },
      { root: true }
    )

    return data
  },

  async uploadDocuments(_, { clubId, documents }) {
    const formData = new FormData()
    Object.keys(documents)
      .filter((key) => !!documents[key])
      .forEach((key) => {
        const doc = documents[key]

        if (Array.isArray(doc)) {
          doc.forEach((d) => formData.append(key, d))
        } else {
          formData.append(key, doc)
        }
      })

    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/mangopay/documents',
      { clubId },
      formData
    )

    return data
  },
}

export const getters = {
  /**
   * Get the bank details.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getBankAccount: (state) => (clubId) => {
    return state.data[clubId]?.bank_account
  },
  /**
   * Get the documents uploaded for a club.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getDocuments: (state) => (clubId) => {
    return state.documents[clubId]
  },
  /**
   * Get all data.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getData: (state) => (clubId) => {
    return state.data[clubId]
  },
  /**
   * Get the legal representative user.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getLegalUser: (state) => (clubId) => {
    return state.data[clubId]?.legal_representative
  },
  /**
   * Get a club mandate.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getMandate: (state) => (clubId) => {
    return state.mandates[clubId]
  },
  /**
   * Get the transactions list for a club.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getTransactions: (state) => (clubId) => {
    return state.transactions[clubId] || []
  },
  /**
   * Get the bank account balance.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  getWallet: (state) => (clubId) => {
    return state.data[clubId]?.wallet
  },
}

export const mutations = {
  /**
   * Merge values with existing for a given club.
   * Merge is not recursive.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @param {Object} values the values to merge with the existing values.
   */
  mergeData(state, { clubId, values }) {
    state.data = {
      ...state.data,
      [clubId]: { ...state.data[clubId], ...values },
    }
  },

  /**
   * Set values for a club.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @param {Object} values the values to set.
   */
  setData(state, { clubId, values }) {
    state.data = { ...state.data, [clubId]: values }
  },

  /**
   * Set documents for a club.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @param {Object} documents the documents to set.
   */
  setDocuments(state, { clubId, documents }) {
    state.documents = { ...state.documents, [clubId]: documents }
  },

  /**
   * Set a mandate for a given club.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @param {Object} mandate the mandate to set.
   */
  setMandate(state, { clubId, mandate }) {
    state.mandates = { ...state.mandates, [clubId]: mandate }
  },

  /**
   * Set transactions for a club.
   * @param {Object} state the current state.
   * @param {Number} clubId the club unique identifier.
   * @param {Object} transactions the transactions to set.
   */
  setTransactions(state, { clubId, transactions }) {
    state.transactions = { ...state.transactions, [clubId]: transactions }
  },
}

const buildStoreLegalAddressFromFormPayload = (payload) => ({
  line_1: payload.club_legal_address_line_1,
  line_2: payload.club_legal_address_line_2,
  city: payload.club_legal_address_city,
  region: payload.club_legal_address_region,
  zipcode: payload.club_legal_address_zipcode,
  country: payload.club_legal_address_country,
})
