
import ClubForm from '~/components/organisms/clubs/ClubForm.vue'
import { getMessageFromErrorResponse } from '~/utils/data'

export default {
  components: { ClubForm },

  emits: ['next', 'setOptions'],

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    club() {
      return this.$store.getters['clubs/getSelected']
    },

    visibleInputs() {
      return ['legal_status', 'street_address', 'postcode', 'town_id']
    },
  },

  methods: {
    async handleSubmit(values) {
      this.loading = true

      try {
        await this.$store.dispatch('clubs/update', values)

        // hide step once completed
        this.$emit('setOptions', { condition: false, required: false })
        // go to next step if any
        this.$emit('next')
      } catch (error) {
        const message = getMessageFromErrorResponse(error, (status, body) => {
          const { code } = body

          switch (status) {
            case 422:
              if (code === 'LegalStatusIsFrozen') {
                return 'error.club.legal_status_frozen'
              }
              break

            default:
              break
          }
        })

        this.$notify({ message, type: 'error' })
        this.$emit('setOptions', { required: false })
      } finally {
        this.loading = false
      }
    },
  },
}
