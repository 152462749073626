
import { ValidationProvider } from 'vee-validate'
import { ValidationMode } from '~/plugins/vee-validate'
import { buildSourceLabelToken } from '~/utils/data'

export default {
  components: { ValidationProvider },

  props: {
    customMessages: {
      type: Object,
      default: undefined,
    },

    label: {
      type: String,
      default: undefined,
    },

    name: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: undefined,
    },

    rules: {
      type: [String, Object],
      default: undefined,
    },

    slim: {
      type: Boolean,
      default: true,
    },

    source: {
      type: String,
      default: undefined,
    },

    tag: {
      type: String,
      default: 'div',
    },

    validationMode: {
      type: String,
      default: ValidationMode.PASSIVE,
    },

    vid: {
      type: [String, Number],
      default: undefined,
    },
  },

  computed: {
    allAttrs() {
      const { label, source } = this._props
      return { ...this.$attrs, label, source }
    },

    labelToken() {
      if (this.source) {
        return buildSourceLabelToken(this.source)
      }

      return this.label ? this.label : undefined
    },

    i18nLabel() {
      return this.labelToken ? this.$tc(this.labelToken, 1) : undefined
    },

    i18nName() {
      return this.name ? this.$tc(this.name, 1) : undefined
    },

    i18nPlaceholder() {
      return this.placeholder ? this.$t(this.placeholder) : undefined
    },

    /**
     * Whether if the current input is required.
     */
    isRequired() {
      switch (typeof this.rules) {
        case 'string':
          return this.rules.split('|').includes('required')

        case 'object':
          return !!this.rules.required

        default:
          return false
      }
    },
  },
}
