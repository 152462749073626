
export default {
  props: {
    verticalAlign: {
      type: String,
      default: 'start',
    },
  },

  computed: {
    align() {
      return {
        'align-center': ['center', 'middle'].includes(this.verticalAlign),
        'align-start': ['start', 'flex-start'].includes(this.verticalAlign),
        'align-end': ['end', 'flex-end'].includes(this.verticalAlign),
      }
    },

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
