import { sortBy } from 'lodash'
import { request } from '~/utils/request'

export const state = () => ({
  positions: {}, // { sport_id: [ position1, position2 ] }
})

export const actions = {
  /**
   * Fetch a list of positions for a sport, and memorize it in the state.
   * @param {Number} sportId the sport ID.
   * @returns {Array<Object>}
   */
  async fetchList({ commit }, sportId) {
    const { data } = await request(this).get('/sports/{sportId}/positions', {
      sportId,
    })

    commit('setSportPositions', { sportId, positions: data })
    return data
  },
}

export const getters = {
  /**
   * Get a list of sport positions for a given sport.
   * @param {Object} state the store state.
   * @param {Number} sportId the sport identifier.
   * @returns {Array} a list of positions for the given sport.
   * If positions are not loaded for the sport, an empty list will be returned.
   */
  getSportPositions: (state) => (sportId) => {
    return state.positions[sportId] || []
  },
}

export const mutations = {
  /**
   * Set or refresh a list of positions for a sport.
   * @param {Object} state the store state.
   * @param {Number} sportId the sport identifier.
   * @param {Array<Object>} positions a list of positions.
   */
  setSportPositions(state, { sportId, positions }) {
    state.positions = {
      ...state.positions,
      [sportId]: sortBy(positions, 'position'),
    }
  },
}
