const LocaleEnum = {
  LOCALE_FR_ID: 1,
  LOCALE_EN_ID: 2,
  LOCALE_DE_ID: 3,
  LOCALE_ES_ID: 4,
  LOCALE_IT_ID: 5,
  LOCALE_PT_ID: 6,
  LOCALE_CODE_FR: 'fr',
  LOCALE_CODE_EN: 'en',
  LOCALE_CODE_DE: 'de',
  LOCALE_CODE_IT: 'it',
  LOCALE_CODE_ES: 'es',
  LOCALE_CODE_PT: 'pt',
}

export const state = () => [
  {
    id: LocaleEnum.LOCALE_FR_ID,
    locale_code: LocaleEnum.LOCALE_CODE_FR,
    name: 'Français',
  },
  {
    id: LocaleEnum.LOCALE_EN_ID,
    locale_code: LocaleEnum.LOCALE_CODE_EN,
    name: 'English',
  },
  {
    id: LocaleEnum.LOCALE_DE_ID,
    locale_code: LocaleEnum.LOCALE_CODE_DE,
    name: 'Deutsch',
  },
  {
    id: LocaleEnum.LOCALE_ES_ID,
    locale_code: LocaleEnum.LOCALE_CODE_ES,
    name: 'Español',
  },
  {
    id: LocaleEnum.LOCALE_IT_ID,
    locale_code: LocaleEnum.LOCALE_CODE_IT,
    name: 'Italiano',
  },
  {
    id: LocaleEnum.LOCALE_PT_ID,
    locale_code: LocaleEnum.LOCALE_CODE_PT,
    name: 'Português',
  },
]

export const actions = {
  /**
   * Load all available locales.
   * @returns {Promise<Array>}
   */
  fetchList({ state }) {
    /**
     * For now, simulate endpoint, locales are defined explicitely.
     * There is no existing endpoint right now, there may be in the future.
     */
    return Promise.resolve(state)
  },
}

export const getters = {
  /**
   * Retrieve a locale from its code.
   * @param {Object} state the state.
   * @param {Number} id the code.
   * @returns {Object}
   */
  getLocaleFromCode: (state) => (code) => {
    return state.find((locale) => locale.locale_code === code)
  },

  /**
   * Retrieve a locale from its identifier.
   * @param {Object} state the state.
   * @param {Number} id the identifier.
   * @returns {Object}
   */
  getLocaleFromId: (state) => (id) => {
    return state.find((locale) => locale.id === id)
  },
}
