
import ClubPicture from '~/components/molecules/clubs/ClubPicture.vue'

export default {
  components: { ClubPicture },

  props: {
    club: {
      type: Object,
      required: true,
    },
  },

  computed: {
    avatarSize() {
      return this.$vuetify.breakpoint.mobile ? 40 : 48
    },
  },
}
