
export default {
  emits: ['confirm'],

  props: {
    open: {
      type: Boolean,
      default: undefined,
    },
  },

  data() {
    return {
      code: '',
      codeInput: '',
      isOpen: false,
    }
  },

  computed: {
    isCodeValid() {
      return this.code === this.codeInput
    },
    dialogOpen: {
      get() {
        return typeof this.open === 'boolean' ? this.open : this.isOpen
      },
      set(val) {
        this.isOpen = val
        this.$emit('close')
      },
    },
  },

  watch: {
    open: {
      immediate: true,
      /**
       * Generate a new code when component is mounted (but closed), and when dialog closes
       * to have a new code each time dialog opens.
       */
      handler(val) {
        if (!val) {
          this.resetCode()
        }
      },
    },
  },

  methods: {
    handleCancel() {
      this.dialogOpen = false
    },

    handleConfirm() {
      if (this.isCodeValid) {
        this.dialogOpen = false
        this.$emit('confirm')
      }
    },

    resetCode() {
      this.code = Math.random().toString(36).slice(4)
      this.codeInput = ''
    },
  },
}
