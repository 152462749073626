import { request } from '~/utils/request'

/* eslint-disable camelcase */
/**
 * Remove values that should not be sent to the server when creating/updating a training session.
 * @param {Object} data the team data.
 * @returns {Object}
 */
const sanitizeTrainingSession = ({
  title,
  team_id,
  day_id,
  hour,
  description,
  place,
  attendance_limit,
  start_date,
  end_date,
  alerts_ids,
  players_ids,
  attendance_visibility,
  is_editable_by_team,
}) => {
  const res = {
    title,
    team_id,
    day_id,
    hour: hour
      ? parseInt(hour.match(/(\d{1,2}):\d+/)[1]) * 60 +
        parseInt(hour.match(/\d{1,2}:(\d+)/)[1])
      : null,
    description: description || '',
    place: place || '',
    start_date,
    end_date,
    players_ids,
    attendance_visibility,
    is_editable_by_team,
  }

  if (attendance_limit) {
    res.attendance_limit = parseInt(attendance_limit)
  }
  if (alerts_ids) {
    res.alerts_ids = alerts_ids
  }

  return res
}
/**
 * Remove values that should not be sent to the server when creating/updating an event.
 * @param {Object} data the team data.
 * @returns {Object}
 */
const sanitizeEvent = ({
  title,
  team_id,
  hour,
  appointment_time,
  date,
  description,
  place,
  attendance_limit,
  alerts_ids,
  players_ids,
  attendance_visibility,
  is_editable_by_team,
}) => {
  const res = {
    title,
    team_id,
    hour: hour
      ? parseInt(hour.match(/(\d{1,2}):\d+/)[1]) * 60 +
        parseInt(hour.match(/\d{1,2}:(\d+)/)[1])
      : null,
    appointment_time: appointment_time || null,
    date,
    description: description || '',
    place: place || '',
    players_ids: players_ids || [],
    attendance_visibility,
    is_editable_by_team,
  }

  if (attendance_limit) {
    res.attendance_limit = parseInt(attendance_limit)
  }
  if (alerts_ids) {
    res.alerts_ids = alerts_ids
  }

  return res
}

/**
 * Remove values that should not be sent to the server when creating/updating a match.
 * @param {Object} data the team data.
 * @returns {Object}
 */
const sanitizeMatch = ({
  opponent_name,
  place,
  team_id,
  hour,
  appointment_time,
  date,
  description,
  attendance_limit,
  alerts_ids,
  players_ids,
  attendance_visibility,
  is_editable_by_team,
}) => {
  const res = {
    opponent_name,
    team_id,
    hour: hour
      ? parseInt(hour.match(/(\d{1,2}):\d+/)[1]) * 60 +
        parseInt(hour.match(/\d{1,2}:(\d+)/)[1])
      : null,
    appointment_time: appointment_time
      ? parseInt(appointment_time.match(/(\d{1,2}):\d+/)[1]) * 60 +
        parseInt(appointment_time.match(/\d{1,2}:(\d+)/)[1])
      : null,
    date,
    description: description || '',
    place: place || '',
    players_ids,
    attendance_visibility,
    is_editable_by_team,
  }
  if (attendance_limit) {
    res.attendance_limit = parseInt(attendance_limit)
  }
  if (alerts_ids) {
    res.alerts_ids = alerts_ids
  }

  return res
}
/* eslint-enable camelcase */

export const actions = {
  /**
   * List all events.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} teamId the team unique identifier.
   * @param {Number} type the type of event.
   * @param {Number} perPage the number of events per page.
   * @param {Number} nextPageToken the next page token.
   * @param {String} startDate
   * @param {String} enddate
   * @returns {Promise}
   */
  async fetchList(
    _,
    { clubId, teamId, type, perPage, nextPageToken, startDate, endDate }
  ) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/events',
      {
        clubId,
      },
      {
        end_date: endDate,
        next_page_token: nextPageToken,
        per_page: perPage,
        start_date: startDate,
        team_id: teamId,
        type,
      }
    )
    return data
  },

  /**
   * List all past events.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} team_id the team unique identifier.
   * @param {Number} type the type of event.
   * @param {Number} perPage the number of events per page.
   * @param {Number} next_page_token the next page token.
   * @returns {Promise}
   */
  async fetchPastList(_, { clubId, teamId, type, perPage, nextPageToken }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/past-events',
      {
        clubId,
      },
      {
        team_id: teamId,
        type,
        per_page: perPage,
        next_page_token: nextPageToken,
      }
    )
    return data
  },

  /**
   * Retrieve a training session by its ID.
   * @param {Number} eventId the training session unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchTrainingSession(_, { eventId }) {
    const { data } = await request(this).get('/training-sessions/{eventId}', {
      eventId,
    })
    return data
  },

  /**
   * Retrieve a recurrent events session by its ID.
   * @param {Number} eventId the training session unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchRecurrentEvent(_, { eventId }) {
    const { data } = await request(this).get('/recurrent-events/{eventId}', {
      eventId,
    })
    return data
  },

  /**
   * Retrieve a event by its ID.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchEvent(_, { eventId }) {
    const { data } = await request(this).get('/events/{eventId}', { eventId })
    return data
  },

  /**
   * Retrieve a match by its ID.
   * @param {Number} eventId the match unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchMatch(_, { eventId }) {
    const { data } = await request(this).get('/matches/{eventId}', { eventId })
    return data
  },

  /**
   * Retrieve participants of an event.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchEventParticipants(_, { eventId }) {
    const { data } = await request(this).get(
      '/events/{eventId}/participants',
      { eventId },
      {}
    )
    return data
  },

  /**
   * Retrieve participants of a match.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchMatchParticipants(_, { eventId }) {
    const { data } = await request(this).get(
      '/matches/{eventId}/participants',
      { eventId },
      {}
    )
    return data
  },

  /**
   * Retrieve participants of a recurrent event.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchRecurrentParticipants(_, { eventId }) {
    const { data } = await request(this).get(
      '/recurrent-events/{eventId}/participants',
      { eventId },
      {}
    )
    return data
  },

  /**
   * Create a training sessions.
   * @param {Number} clubId the club ID.
   * @param {Object} values the event values.
   * @returns {Promise<Object>} the created event.
   */
  async createTrainingSession(_, { clubId, values }) {
    const { data } = await request(this).post(
      '/clubs/{clubId}/training-sessions',
      { clubId },
      sanitizeTrainingSession(values)
    )
    return data
  },

  /**
   * Create an event.
   * @param {Object} clubId the clubId values.
   * @param {Object} values the event values.
   * @returns {Promise<Object>} the created event.
   */
  async createEvent(_, { clubId, values }) {
    const { data } = await request(this).post(
      '/clubs/{clubId}/events',
      { clubId },
      sanitizeEvent(values)
    )
    return data
  },

  /**
   * Create a match.
   * @param {Number} clubId the club ID.
   * @param {Object} values the event values.
   * @returns {Promise<Object>} the created event.
   */
  async createMatch(_, { clubId, values }) {
    const { data } = await request(this).post(
      '/clubs/{clubId}/matches',
      { clubId },
      sanitizeMatch(values)
    )
    return data
  },

  /**
   * Create a group of events.
   * @param {Object} clubId the clubId values.
   * @param {Array<Object<id, players_ids>>} teams a list of players by team.
   * @param {Object} values the event values.
   * @returns {Promise<Object>} the created event.
   */
  async createEventsBatch(_, { clubId, teams, values }) {
    const { data } = await request(this).post(
      '/clubs/{clubId}/batch-create-events',
      {
        clubId,
      },
      { teams, event: sanitizeEvent(values) }
    )
    return data
  },

  /**
   * Update an event.
   * @param {Object} values the event values.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise<Object>} the updated event.
   */
  async updateTrainingSession(_, { values, eventId }) {
    const { data } = await request(this).put(
      '/training-sessions/{eventId}',
      { eventId },
      sanitizeTrainingSession(values)
    )
    return data
  },

  /**
   * Update an event.
   * @param {Object} values the event values.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise<Object>} the updated event.
   */
  async updateEvent(_, { values, eventId }) {
    const { data } = await request(this).put(
      '/events/{eventId}',
      { eventId },
      sanitizeEvent(values)
    )
    return data
  },

  /**
   * Update a match.
   * @param {Object} values the event values.
   * @param {Number} matchId the event unique identifier.
   * @returns {Promise<Object>} the updated event.
   */
  async updateMatch(_, { values, matchId }) {
    const { data } = await request(this).put(
      '/matches/{matchId}',
      { matchId },
      sanitizeMatch(values)
    )
    return data
  },

  /**
   * Update a group of events.
   * @param {Object} values the event values.
   * @returns {Promise<Object>} the created event.
   */
  async updateEventsBatch(_, values) {
    const { data } = await request(this).put(
      '/clubs/batch-update-events/{eventId}',
      { eventId: values.id },
      { ...sanitizeEvent(values) }
    )
    return data
  },

  /**
   * Cancel a recurrent event.
   * @param {Number} recurrenteventId the event unique identifier.
   * @returns {Promise}
   */
  async cancelRecurrentEvent(_, { recurrenteventId, isCancelled }) {
    await request(this).put(
      '/recurrent-events/{recurrenteventId}',
      { recurrenteventId },
      { is_cancelled: isCancelled }
    )
  },

  /**
   * Delete an event.
   * @param {Number} eventId the event unique identifier.
   * @returns {Promise}
   */
  async deleteEvent(_, { eventId }) {
    await request(this).delete('/events/{eventId}', { eventId })
  },

  /**
   * Delete a match.
   * @param {Number} matchId the match unique identifier.
   * @returns {Promise}
   */
  async deleteMatch(_, { matchId }) {
    await request(this).delete('/matches/{matchId}', { matchId })
  },

  /**
   * Delete a training session.
   * @param {Number} trainingSessionId the training session unique identifier.
   * @returns {Promise}
   */
  async deleteTrainingSession(_, { trainingSessionId }) {
    await request(this).delete('/training-sessions/{trainingSessionId}', {
      trainingSessionId,
    })
  },
}
