import Vue from 'vue'

import {
  ValidationProvider,
  extend,
  localeChanged,
  localize,
} from 'vee-validate'
import veeDe from 'vee-validate/dist/locale/de.json'
import veeEn from 'vee-validate/dist/locale/en.json'
import veeEs from 'vee-validate/dist/locale/es.json'
import veeFr from 'vee-validate/dist/locale/fr.json'
import veeIt from 'vee-validate/dist/locale/it.json'
import veePt from 'vee-validate/dist/locale/pt_PT.json'
import {
  confirmed,
  double,
  email,
  excluded,
  ext,
  integer,
  is,
  max,
  max_value as maxValue,
  min,
  min_value as minValue,
  numeric,
  required,
  size,
} from 'vee-validate/dist/rules'
import IBAN from 'iban'
import { formatNumber, isEmpty } from '~/utils/data'

Vue.component('ValidationProvider', ValidationProvider)

/**
 * Import the rules you need (none provided by default)
 * @see https://vee-validate.logaretm.com/v3/guide/rules.html#rules
 */
const availableRules = {
  confirmed,
  double,
  email,
  excluded,
  ext,
  integer,
  is,
  max,
  max_value: maxValue,
  min,
  min_value: minValue,
  numeric,
}
Object.keys(availableRules).forEach((key) => extend(key, availableRules[key]))

// available locales
localize({ de: veeDe, en: veeEn, es: veeEs, fr: veeFr, it: veeIt, pt: veePt })

// Modes for validation
export const ValidationMode = {
  AGGRESSIVE: 'aggressive',
  PASSIVE: 'passive',
  LAZY: 'lazy',
  EAGER: 'eager',
}

export default ({ i18n }, inject) => {
  /**
   * Create custom rules.
   * Define them here to use i18n translation functions
   * @see https://vee-validate.logaretm.com/v3/guide/localization.html#vue-i18n
   */
  const customRules = {
    bic: {
      /**
       * Ensure a value is a valid BIC.
       * @param {String} value the bic value.
       * @returns {Boolean}
       */
      validate(value) {
        const regex = /^\w{8}$|^\w{11}$/im
        return regex.exec(value)
      },
      message: (_, values) => i18n.t('validation.bic', values),
    },
    boolean: {
      /**
       * Ensure a value is a boolean.
       * @param {*} value
       * @returns {boolean}
       */
      validate(value) {
        return typeof value === 'boolean'
      },
      message: (_, values) => i18n.t('validation.boolean', values),
    },
    checked: {
      ...required,
      /**
       * Ensure a checkbox is checked.
       * @param {*} value
       * @returns {Boolean}
       */
      validate(value) {
        return required.validate(value, { allowFalse: false })
      },
      message: (_, values) => i18n.t('validation.checked', values),
    },
    greater_than: {
      /**
       * Ensure a value is greater than another.
       * @param {*} value the value to compare.
       * @param {*} other the reference value.
       * @param {Boolean} equal whether to perform a "greater than equal" or a strict "greater than".
       * @returns {Boolean}
       */
      validate(value, { field, equal = false }) {
        return equal ? value >= field : value > field
      },
      params: ['field', 'equal'],
      message: (_, values) => i18n.t('validation.greaterThan', values),
    },
    iban: {
      /**
       * Ensure an IBAN is valid.
       * @param {String} value the iban value.
       * @returns {Boolean}
       */
      validate(value) {
        return IBAN.isValid(value)
      },
      message: (_, values) => i18n.t('validation.iban', values),
    },
    less_than: {
      /**
       * Ensure a value is less than another.
       * @param {*} value the value to compare.
       * @param {*} other the reference value.
       * @param {Boolean} equal whether to perform a "less than equal" or a strict "less than".
       * @returns {Boolean}
       */
      validate(value, { field, equal = false }) {
        return equal ? value <= field : value < field
      },
      params: ['field', 'equal'],
      message: (_, values) => i18n.t('validation.lessThan', values),
    },
    max_items: {
      /**
       * Ensure an array has at least a given number of items.
       * @param {Array} value the value to check.
       * @param {Number} max the maximum number of items.
       * @returns {Boolean}
       */
      validate(value, { max }) {
        if (!Array.isArray(value)) {
          return false
        }

        return value.length <= max
      },
      params: ['max'],
      message: (_, values) => i18n.tc('validation.maxElements', max, values),
    },
    max_file_type_count: {
      /**
       * Ensure a list of files contains at most a given number of files with a given mime type.
       * @param {Array} value a list of files.
       * @param {Number} max the maximum number of files.
       * @param {String} param1 the mime type of check.
       * @returns {Boolean}
       */
      validate(value, { max, mimeType }) {
        const matchingFiles = value.filter((f) => {
          const mimeParts = f.type.split('/')
          const expectedMimeParts = mimeType.split('/')

          return (
            mimeParts[0] === expectedMimeParts[0] &&
            (mimeParts[1] === expectedMimeParts[1] ||
              expectedMimeParts[1] === '*')
          )
        })

        return matchingFiles.length <= max
      },
      params: ['max', 'mimeType'],
      message: (_, values) =>
        i18n.tc('validation.maxFileTypeCount', max, values),
    },
    min_items: {
      /**
       * Ensure an array has at least a given number of items.
       * @param {Array} value the value to check.
       * @param {Number} min the minimum number of items.
       * @returns {Boolean}
       */
      validate(value, { min }) {
        if (!Array.isArray(value)) {
          return false
        }

        return value.length >= min
      },
      params: ['min'],
      message: (_, values) => i18n.tc('validation.minElements', min, values),
    },
    /**
     * Ensure no empty item is in the list.
     * @param {Array} value the value to check.
     * @returns {Boolean}
     */
    no_empty_item: {
      validate(value) {
        if (!Array.isArray(value)) {
          return false
        }

        return value.filter((val) => isEmpty(val)).length === 0
      },
    },
    no_space: {
      /**
       * Ensure a string doesn't contains space.
       * @param {String} value the value to check.
       * @returns {Boolean}
       */
      validate(value) {
        const pattern = /^\S*$/
        return pattern.test(value)
      },
      message: (_, values) => i18n.t('validation.noSpace', values),
    },
    phone: {
      /**
       * Ensure a string has a valid phone number format.
       * @param {String} value the value to check.
       * @returns {Boolean}
       */
      validate(value) {
        const phoneRegex =
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
        return phoneRegex.test(value)
      },
      message: (_, values) => i18n.t('validation.phone', values),
    },
    required: {
      ...required,
      /**
       * Ensure a value is not empty.
       * Ensure a string is not empty or composed only with spaces.
       * Ensure an array is not empty.
       * @param {*} value the value to check.
       * @returns {Boolean}
       */
      validate(value) {
        return !isEmpty(value)
      },
    },
    size: {
      ...size,
      message: (_, values) => i18n.t('validation.fileSizeMaxMb', values),
    },
    size_between: {
      /**
       * Ensure a file has a size in a range.
       * @param {File|Array<File>} value the file to check
       * @param {Number} min the minimum file size
       * @param {Number} max the maximum file size
       * @returns {Boolean}
       */
      validate(value, { min, max, unitMin = 'K', unitMax = 'M' }) {
        const files = (Array.isArray(value) ? value : [value]).filter(Boolean)

        let minSize = Number(min)
        let maxSize = Number(max)

        if (['M', 'm'].includes(unitMax)) {
          maxSize *= 1024 * 1024
        } else if (['K', 'k'].includes(unitMax)) {
          maxSize *= 1024
        }

        if (['M', 'm'].includes(unitMin)) {
          minSize *= 1024 * 1024
        } else if (['K', 'k'].includes(unitMin)) {
          minSize *= 1024
        }

        const isValid = !files.find((f) => f.size < minSize || f.size > maxSize)

        return isValid
      },
      params: ['min', 'max', 'unitMin', 'unitMax'],
      message: (_, values) =>
        i18n.t('validation.fileSizeBetweenMb', {
          min: formatNumber(values.min, i18n.locale),
          max: formatNumber(values.max, i18n.locale),
        }),
    },
  }

  Object.keys(customRules).forEach((key) => extend(key, customRules[key]))

  // activate the locale to use
  localize(i18n.locale)

  inject('veeValidate', { localize, localeChanged, ValidationMode })
}
