
import Logout from '~/components/molecules/auth/Logout.vue'
import UserBadge from '~/components/molecules/auth/UserBadge.vue'
import UserAccountMenuItem from '~/components/molecules/user/UserAccountMenuItem.vue'

export default {
  components: { Logout, UserAccountMenuItem, UserBadge },

  computed: {
    user() {
      return this.$auth.user
    },
  },

  methods: {
    /**
     * Disconnect the current user.
     */
    logout() {
      this.$auth.logout()
    },
  },
}
