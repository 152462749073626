export default async function ({ $auth, route, store }) {
  const clubId =
    route.params && route.params.club_id
      ? parseInt(route.params.club_id)
      : undefined
  const userId = $auth.user ? $auth.user.id : undefined
  let clubsList = []

  store.commit('chatRooms/setCurrentUserId', userId)

  /*
  if (route.name === 'clubs') {
    // clubs are fetch by the component matching this route
  }
  */

  if (
    route.name &&
    (route.name.startsWith('clubs-') || route.name === 'account')
  ) {
    // prefetch clubs list of other clubs
    if (!store.getters['clubs/isListFetchedOnce']) {
      clubsList = await store.dispatch('clubs/fetchList')
    }

    // refresh the number of unread message in the menu at each page change
    try {
      store.dispatch('chatRooms/fetchUnreadMessagesCount')
    } catch (error) {}
  }

  const selectedClub = store.getters['clubs/getSelected']

  if (clubId) {
    // if selected club in state is undefined, or different from the url, fetch it

    if (!selectedClub || selectedClub.id !== clubId) {
      // try to retrieve club from list if list has just been fetched
      let club = clubsList.find((club) => club.id === clubId)

      if (!club) {
        // if not found, or list not just fetched, fetch club from server to have fresh data
        try {
          club = await store.dispatch('clubs/fetchOne', { id: clubId })
        } catch (error) {
          if (error.response?.status === 403) {
            // force redirection to 404 page
            error.response.status = 404
          }

          throw error
        }
      }

      store.commit('clubs/setSelected', club)
    }
  } else if (clubsList.length && !selectedClub) {
    store.commit('clubs/setSelected', clubsList[0])
  }
}
