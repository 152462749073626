import { request } from '~/utils/request'

export const actions = {
  /**
   * Create a new post.
   * @param {Number} clubId the club unique identifier.
   * @param {Object} values the post data.
   * @param {Array<Object>} documents a list of documents to attach to the post.
   * @param {Array<Object>} images a list of images to attach to the post.
   * @param {Object} images a video to attach to the post.
   * @returns {Promise<Object>}
   */
  async create(
    { dispatch },
    { clubId, teamsIds, values, documents = [], images = [], video = null }
  ) {
    let documentsPayload, imagesPayload, videoPayload

    if (documents.length || images.length || video) {
      const {
        documents: documentsData,
        images: imagesData,
        video: videoData,
      } = await dispatch(
        'files/uploadPostFiles',
        {
          documents,
          images,
          video,
        },
        { root: true }
      )

      documentsPayload = documentsData.length ? documentsData : undefined
      imagesPayload = imagesData.length ? imagesData : undefined
      videoPayload = videoData || undefined
    }

    const { data } = await request(this).post(
      '/clubs/{clubId}/batch-create-posts',
      { clubId },
      {
        teams: teamsIds.map((id) => ({ id, players_ids: [] })),
        post: {
          ...values,
          documents: documentsPayload,
          photos: imagesPayload,
          video: videoPayload,
        },
      }
    )
    return data
  },

  /**
   * Create a post comment.
   * @param {Number} postId the post unique identifier.
   * @param {String} text the comment content.
   * @returns {Promise<Object>}
   */
  async createComment(_, { postId, text }) {
    const { data } = await request(this).post(
      '/posts/{postId}/comments',
      { postId },
      { text }
    )
    return data
  },

  /**
   * Create a new reaction on a post.
   * @param {Number} postId the post unique identifier.
   * @param {Number} emojiType the reaction emoji
   * @returns {Promise<Object>} the created reaction.
   */
  async createReaction(_, { postId, emojiType }) {
    const { data } = await request(this).post(
      '/posts/{postId}/reactions',
      { postId },
      { emoji_type: emojiType }
    )
    return data
  },

  /**
   * Delete a post.
   * @param {Number} id the post unique identifier.
   * @returns {Promise}
   */
  async delete(_, { id }) {
    const { data } = await request(this).delete('/posts/{postId}', {
      postId: id,
    })
    return data
  },

  /**
   * Delete a post having a group_uuid.
   * @param {Number} id the post unique identifier.
   * @returns {Promise}
   */
  async deleteBatch(_, { id }) {
    const { data } = await request(this).delete(
      '/clubs/batch-delete-posts/{postId}',
      { postId: id }
    )
    return data
  },

  /**
   * Delete a post comment.
   * @param {Number} id the comment unique identifier.
   * @returns {Promise}
   */
  async deleteComment(_, { id }) {
    const { data } = await request(this).delete('/comments/{commentId}', {
      commentId: id,
    })
    return data
  },

  /**
   * Delete a reaction on a post.
   * @param {Number} param1 the reaction unique identifier.
   * @returns {Promise}
   */
  async deleteReaction(_, { id }) {
    const { data } = await request(this).delete('/reactions/{reactionId}', {
      reactionId: id,
    })
    return data
  },

  /**
   * Fetch the list of comments of a post.
   * @param {Number} param1 the post unique identifier.
   * @returns {Promise<Array<Object>>}
   */
  async fetchCommentsList(_, { postId }) {
    const { data } = await request(this).get('/posts/{postId}/comments', {
      postId,
    })
    return data
  },

  /**
   * Fetch a list of posts published by a club administrators.
   * @returns {Promise<Object>} the request response, containing the posts lists, a next page token and a total.
   */
  async fetchList(_, { clubId, nextPageToken, perPage, teamId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/posts',
      { clubId },
      { team_id: teamId, next_page_token: nextPageToken, per_page: perPage }
    )

    return data
  },

  /**
   * Fetch a list of posts published by a team members.
   * @returns {Promise<Object>}
   */
  async fetchTeamPostsList(_, { clubId, nextPageToken, perPage, teamId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/teams/{teamId}/posts',
      { clubId, teamId },
      { next_page_token: nextPageToken, per_page: perPage }
    )

    return data
  },

  /**
   * Fetch list of post consultations.
   * @returns {Promise<Object>}
   */
  async fetchViews(_, { postId }) {
    const { data } = await request(this).get('/posts/{postId}/views', {
      postId,
    })

    return data
  },

  /**
   * Update a post.
   * @param {Number} postId the post unique identifier.
   * @param {Object} values the new post values.
   * @returns {Promise<Object>}
   */
  async update(_, { postId, values }) {
    const { data } = await request(this).put(
      '/clubs/batch-update-posts/{postId}',
      { postId },
      values
    )
    return data
  },
}
