
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const FIELD_SCOPE_PREFIX = 'field.'

export default {
  emits: ['submit'],

  components: { ValidationObserver, ValidationProvider },

  props: {
    labels: {
      type: Object,
      default: () => ({}),
    },

    rules: {
      type: Object,
      default: () => ({}),
    },

    slim: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      valid: false,
    }
  },

  computed: {
    /**
     * The fields slots to wrap with a validation provider.
     * @returns {Object} {scopeName: fieldName}
     */
    fieldsSlots() {
      return Object.keys(this.$scopedSlots)
        .filter((scopeName) => scopeName.startsWith(FIELD_SCOPE_PREFIX))
        .reduce(
          (acc, scopeName) => ({
            ...acc,
            [scopeName]: scopeName.substring(FIELD_SCOPE_PREFIX.length),
          }),
          {}
        )
    },
  },

  methods: {
    /**
     * Emit 'submit' event only if the form is valid.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Reset the current form (Can be call by the parent component with `this.$refs.nameRef.reset()`).
     */
    reset() {
      this.$refs.observer.reset()
    },

    /**
     * Sets error messages on validation provider instances, the key should match each provider's vid or name.
     * @param {Object<string, String|Array<String>} props the errors.
     */
    setErrors(...props) {
      this.$refs.observer.setErrors(...props)
    },
  },
}
