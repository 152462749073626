export const state = () => {
  return {
    build: {
      checkedAt: null,
      isOutdated: false,
    },
    routeFrom: null,
    sidebarOpen: null,
  }
}

export const getters = {
  /**
   * Get the last check timestamp.
   * @param {Object} state the current state.
   * @returns {Number} the last check timestamp
   */
  getBuildLastCheckedAt(state) {
    return state.build.checkedAt
  },

  /**
   * Get the memorized route.
   * @param {Object} state the current state.
   * @returns {Object} the previous route.
   */
  getRouteFrom(state) {
    return state.routeFrom
  },

  /**
   * Whether if the current app is outdated and needs to be reloaded.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isBuildOutdated(state) {
    return state.build.isOutdated
  },

  /**
   * Whether if the sidebar is open or not.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isSidebarOpen(state) {
    return state.sidebarOpen
  },
}

export const mutations = {
  /**
   * Update the current build state.
   * @param {Object} state the current state.
   * @param {Number} checkedAt the last check timestamp.
   * @param {Boolean} isOutdated whether if the app is outdated.
   */
  setBuildChecked(state, { checkedAt, isOutdated }) {
    state.build.checkedAt = checkedAt
    state.build.isOutdated = isOutdated
  },

  /**
   * Update the outdated app status.
   * @param {Object} state the current state.
   * @param {Boolean} isOutdated whether if the app is outdated.
   */
  setBuildOutdated(state, isOutdated) {
    state.build.isOutdated = isOutdated
  },

  /**
   * Remeber previous route when navigating.
   * @param {Object} state the current state.
   * @param {Object} route the route to remember.
   */
  setRouteFrom(state, route) {
    state.routeFrom = {
      name: route.name,
      params: { ...route.params },
      hash: route.hash,
      path: route.path,
      query: route.query ? { ...route.query } : undefined,
    }
  },

  /**
   * Set sidebar open or closed.
   * @param {Object} state the current state.
   * @param {Boolean} open the new state.
   */
  setSidebarOpen(state, open) {
    state.sidebarOpen = open
  },
}
