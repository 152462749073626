
import { buildSourceLabelToken } from '~/utils/data'

export default {
  props: {
    backgroundColor: {
      type: String,
      default: 'white',
    },
    centeredLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: [Number, String],
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto',
    },
    label: {
      type: String,
      default: undefined,
    },
    hideRequiredLabel: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tFormValidatorRequired: {
      /**
       * Use this to add the `*` in the label, without applying the `required`  property on the field (can be anoying on custom inputs).
       * @see `TFormValidator`
       */
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: null,
    },
  },

  computed: {
    /**
     * All the properties to forward to the input component.
     * Includes implicit attributes and explicit properties.
     * @returns {Object}
     */
    allProps() {
      const props = { ...this._props }
      delete props.label // to avoid default placeholder in input

      if (this.solo) {
        props.outlined = false
        props.flat = true
      }

      return { ...this.$attrs, ...props }
    },
    /**
     * Classes to apply to the input when solo props.
     * @returns {Array}
     */
    inputClasses() {
      return this.solo
        ? [
            'v-sheet',
            this.elevation === null
              ? 'elevation-1'
              : `elevation-${this.elevation}`,
            'align-self-baseline',
            'rounded',
          ]
        : null
    },
    /**
     * Whether if this field is required or not.
     * @returns {Boolean}
     */
    isRequired() {
      if (this.tFormValidatorRequired) {
        return true
      }

      if (this.rules) {
        return this.rules.findIndex((rule) => rule.name === 'required') > -1
      }

      return this.required
    },
    /**
     * Classes to apply to the input label.
     * @returns {Array}
     */
    labelClasses() {
      return [
        !this.solo && 'text-h4',
        !this.solo && 'mb-1',
        this.disabled && 'text--disabled',
        this.centeredLabel && 'text-center',
      ]
    },
    /**
     * The label to display.
     * @returns {String}
     */
    translatedLabel() {
      const token = this.label || buildSourceLabelToken(this.source)

      if (!token) {
        return undefined
      }

      return this.$tc(token, 1)
    },
  },
}
