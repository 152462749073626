import FileSaver from 'file-saver'
import { request } from '~/utils/request'

/* eslint-disable camelcase */
/**
 * Remove values that should not be sent to the server when creating/updating an accounting line.
 * @param {Object} data the team data.
 * @returns {Object}
 */
const sanitizeAccountingLine = ({
  payment_id,
  player_id,
  payment_type_id,
  payment_subject_id,
  payment_third_party_id,
  payment_method,
  credit_amount,
  debit_amount,
  paid_at,
  reference,
}) => {
  const res = {
    payment_id,
    player_id,
    payment_type_id: payment_type_id.id || null,
    payment_type_label:
      typeof payment_type_id === 'string' ? payment_type_id : null,
    payment_subject_id: payment_subject_id.id || null,
    payment_subject_label:
      typeof payment_subject_id === 'string' ? payment_subject_id : null,
    payment_third_party_id: payment_third_party_id.id || null,
    payment_third_party_label:
      typeof payment_third_party_id === 'string'
        ? payment_third_party_id
        : null,
    payment_method: payment_method.id || null,
    is_credit: credit_amount > 0,
    credit_amount: credit_amount * 1,
    is_debit: debit_amount > 0,
    debit_amount: debit_amount * 1,
    paid_at,
    reference,
  }

  return res
}
/* eslint-enable camelcase */

export const actions = {
  /**
   * List all accounting lines.
   * @param {Object} clubId the club id.
   * @param {Object} filters the filters.
   * @param {Number} page the page number.
   * @param {Number} perPage the number of items per page.
   * @param {String} sortOrder the sort order.
   * @returns {Promise<Object>}
   */
  async fetchList(_, { clubId, filters, page, perPage, sortOrder }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/accounting-lines',
      { clubId },
      {
        ...filters,
        is_credit:
          typeof filters.is_credit === 'boolean'
            ? Number(filters.is_credit)
            : undefined,
        is_debit:
          typeof filters.is_debit === 'boolean'
            ? Number(filters.is_debit)
            : undefined,
        page,
        per_page: perPage,
        sort: sortOrder,
        payment_type_id: filters.payment_type_id,
        payment_subject_id: filters.payment_subject_id,
        payment_third_party_id: filters.payment_third_party_id,
        payment_method: filters.payment_method,
        paid_at_from: filters.paid_at_from || null,
        paid_at_to: filters.paid_at_to || null,
        name: filters.search_name || null,
      }
    )

    return data
  },

  /**
   * Download accounting lines maching some criteria in a CSV file.
   * @returns {Promise<Object>}
   */
  async downloadCsvList(_, { clubId, filters, sortOrder }) {
    const { data, headers } = await request(this, 'club').get(
      '/clubs/{clubId}/accounting-lines/export',
      { clubId },
      {
        ...filters,
        is_credit:
          typeof filters.is_credit === 'boolean'
            ? Number(filters.is_credit)
            : undefined,
        is_debit:
          typeof filters.is_debit === 'boolean'
            ? Number(filters.is_debit)
            : undefined,
        sort: sortOrder,
      }
    )

    FileSaver.saveAs(
      new Blob([data]),
      headers['content-disposition'].match(/filename="(.*)"/)[1]
    )

    return data
  },

  /**
   * Retrieve a payment type from its ID.
   * @param {Number} id the payment type unique identifier.
   * @param {Number} clubId the club unique identifier.
   * @returns {Object}
   */
  async getPaymentType(_, { id, clubId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payment-types/{id}',
      { clubId, id }
    )
    return data
  },

  /**
   * List all PaymentThirdParty.
   * @returns {Promise<Array>}
   */
  async listPaymentThirdParty(_, { clubId, label, perPage }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payment-third-parties',
      { clubId },
      {
        q: label,
        per_page: perPage,
      }
    )

    return data
  },

  /**
   * List all PaymentType.
   * @returns {Promise}
   */
  async listPaymentType(_, { clubId, label, perPage }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payment-types',
      { clubId },
      {
        q: label,
        per_page: perPage,
      }
    )

    return data
  },

  /**
   * List all PaymentSubject.
   * @returns {Promise<Array>}
   */
  async listPaymentSubject(_, { clubId, label, perPage }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payment-subjects',
      { clubId },
      {
        q: label,
        per_page: perPage,
      }
    )

    return data
  },

  /**
   * List all PaymentMethod.
   * @returns {Promise<Array>}
   */
  async listPaymentMethod(_, { clubId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payment-methods',
      { clubId }
    )

    return data
  },

  /**
   * Search accounting lines with a given query.
   * @param {Number} clubId the club for which to search accounting lines.
   * @param {String} label the label to search.
   * @returns {Promise<Array>}
   */
  async searchByName(_, { clubId, name }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/accounting-lines/search-name',
      { clubId },
      {
        q: name,
      }
    )
    return data
  },

  /**
   * Create an accounting line.
   * @param {Number} clubId the club in which to create the accounting line.
   * @param {Object} values the accounting values.
   * @returns {Promise<Object>} the created accounting.
   */
  async create(_, { clubId, values }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/accounting-lines',
      { clubId },
      sanitizeAccountingLine(values)
    )

    return data
  },

  /**
   * Delete an accounting line.
   * @param {Number} clubId the club holding the accounting line.
   * @param {Number} accountingId the accounting line to delete.
   * @returns {Promise<Object>} the deleted accounting.
   */
  async delete(_, { clubId, accountingId }) {
    const { data } = await request(this, 'club').delete(
      '/clubs/{clubId}/accounting-lines/{accountingId}',
      { clubId, accountingId }
    )

    return data
  },
}
