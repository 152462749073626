import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

export default function (_, inject) {
  const clients = {
    /**
     * Build an S3 client for a given token.
     * @param {Object} awsToken the aws token.
     * @param {Object} config the bucket configuration.
     * @returns {S3Client}
     */
    getClient: (awsToken, config) => {
      const credentials = {
        accessKeyId: awsToken.access_key_id,
        secretAccessKey: awsToken.secret_access_key,
        sessionToken: awsToken.session_token,
        expiration: awsToken.expiration,
      }

      return new S3Client({
        credentials,
        endpoint: config.endpoint || undefined,
        region: config.region || undefined,
      })
    },

    /**
     * Get data to send to S3 when uploading a file.
     * @param {String} bucket the bucket name.
     * @param {String} path the file path in the bucket (with the file name).
     * @param {Blob} body the file content.
     * @param {String} type the file content type.
     * @returns
     */
    buildFileUploadObject: (bucket, path, body, type) => {
      return new PutObjectCommand({
        Bucket: bucket,
        Key: path,
        Body: body,
        ContentType: type,
      })
    },

    /**
     * Get the url of a file on S3.
     * @param {String} path the file path in the bucket.
     * @param {Object<bucket,endpoint,region>} config the bucket configuration.
     * @returns {String}
     */
    buildFileUrl(path, config = {}) {
      return new URL(
        path,
        `https://${config.bucket}.${
          config.endpoint ||
          `s3.${config.region ? `${config.region}.` : ''}amazonaws.com`
        }`
      ).toString()
    },
  }

  inject('s3', clients)
}
