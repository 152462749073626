
import Logout from '~/components/molecules/auth/Logout.vue'
import UserBadge from '~/components/molecules/auth/UserBadge.vue'
import UserAccountMenuItem from '~/components/molecules/user/UserAccountMenuItem.vue'
import ClubJoinDialog from '~/components/organisms/clubs/ClubJoinDialog.vue'
import ClubMenuItemsList from '~/components/organisms/menu/ClubMenuItemsList.vue'
import ClubSelectedMenuItem from '~/components/organisms/menu/ClubSelectedMenuItem.vue'
import MenuItemsList from '~/components/organisms/menu/MenuItemsList.vue'
import MenuSelectClubBtn from '~/components/organisms/menu/MenuSelectClubBtn.vue'

export default {
  components: {
    ClubJoinDialog,
    ClubMenuItemsList,
    ClubSelectedMenuItem,
    Logout,
    MenuItemsList,
    MenuSelectClubBtn,
    UserAccountMenuItem,
    UserBadge,
  },

  data() {
    return {
      isClubSelectOpen: false,
    }
  },

  computed: {
    clubsList() {
      return this.$store.getters['clubs/getList']
    },

    club() {
      return this.$store.getters['clubs/getSelected']
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    user() {
      return this.$auth.user
    },
  },
}
