
import debounce from 'lodash/debounce'
import { CLUB_LEGAL_STATUSES } from '~/models/enums'
import {
  buildPictureUrl,
  getBrowserTimezone,
  getSportNameAttribute,
  isFrenchTimezone,
} from '~/utils/data'

export default {
  emits: ['submit'],

  props: {
    club: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: 'phrase.label_save',
    },
    visibleInputs: {
      type: Array,
      default: () => [
        'club_picture',
        'name',
        'code',
        'sport_id',
        'timezone_id',
        'legal_status',
        'street_address',
        'postcode',
        'town_id',
        'payment_instructions',
      ],
    },
  },

  fetch() {
    Promise.all([
      this.fetchSports(),
      this.fetchTimezones().then(async () => {
        if (this.club?.town?.postcode) {
          await this.fetchTowns(this.club.town.postcode)
        }
      }),
    ]).finally(() => {
      this.loadingForm = false
    })
  },

  data() {
    return {
      fields: {
        name: null,
        code: null,
        sport_id: null,
        street_address: null,
        timezone_id: null,
        town_id: null,
        postcode: null,
        payment_instructions: null,
        legal_status: null,
        ...this.club,
        ...(this.club.town ? { postcode: this.club.town.postcode } : {}),
      },
      uploadFile: this.club.is_club_pic_uploaded
        ? buildPictureUrl(this.club.club_pic_url, this.club.updated_at)
        : null,
      isPostcodeValid: true,
      loadingForm: true,
      sports: [],
      timezones: [],
      towns: [],
    }
  },

  computed: {
    /**
     * Whether if the current selected timezone is the french one.
     * @returns {Boolean}
     */
    isFrenchTimezone() {
      return isFrenchTimezone(this.timezones, this.fields.timezone_id)
    },

    /**
     * The list of legal statuses available.
     * @returns {Array<Object>}
     */
    legalStatuses() {
      return CLUB_LEGAL_STATUSES.map((item) => ({
        ...item,
        label: this.$t(item.label),
      }))
    },

    /**
     * The sport name property depends on the locale.
     */
    sportItemText() {
      return getSportNameAttribute(this)
    },
  },

  watch: {
    club: {
      deep: true,
      handler(val) {
        this.fields = {
          ...this.fields,
          ...val,
          ...(val.town ? { postcode: val.town.postcode } : {}),
        }
        this.uploadFile = val.is_club_pic_uploaded
          ? buildPictureUrl(val.club_pic_url, val.updated_at)
          : null
      },
    },
    /**
     * Fetch cities list when zipcode is updated.
     */
    'fields.postcode'(val) {
      this.fields.town_id = null

      if (!val) {
        this.isPostcodeValid = true
        this.showPostCodeInvalidError()
        this.towns = []
      } else {
        this.debouncedFetchTowns(val)
      }
    },
    isFrenchTimezone(val) {
      if (!val) {
        this.fields.postcode = null
        this.fields.street_address = null
      }
    },
  },

  created() {
    this.debouncedFetchTowns = debounce(this.fetchTowns, 400)
  },

  methods: {
    /**
     * Fetch the list of available sports.
     */
    async fetchSports() {
      try {
        this.sports = await this.$store.dispatch('sports/fetchList')
      } catch (error) {
        this.$notify({ error })
      }
    },

    /**
     * Fetch the available timezones.
     * If no timezone is set and the browser timezone is in the fetched results, preset it in form.
     */
    async fetchTimezones() {
      try {
        const timezones = await this.$store.dispatch('timezones/fetchList')
        this.timezones = timezones

        if (!this.fields.timezone_id) {
          const browserTimezone = getBrowserTimezone()
          if (browserTimezone) {
            const clientTimezone = timezones.find(
              (t) => t.name === browserTimezone
            )
            if (clientTimezone) {
              this.fields.timezone_id = clientTimezone.id
            }
          }
        }
      } catch (error) {
        this.$notify({ error })
      }
    },

    /**
     * Fetch the list of available cities.
     * @param {String} postcode the zipcode used to fetch the cities list.
     */
    async fetchTowns(postcode) {
      if (!this.isFrenchTimezone || !postcode) {
        return
      }

      let towns = []
      this.isPostcodeValid = true

      try {
        towns = await this.$store.dispatch('towns/fetchList', { postcode })
      } catch (error) {
        if ([400].includes(error.response?.status)) {
          this.isPostcodeValid = false
        } else {
          this.$notify({ error })
        }
      }

      this.towns = towns
      if (!this.towns.length || !this.isPostcodeValid) {
        this.showPostCodeInvalidError(true)
      }

      if (this.towns.length === 1) {
        this.fields.town_id = this.towns[0].id
      }
    },

    /**
     * Try to authenticate user.
     */
    handleSubmit() {
      if (!this.loading) {
        const data = { ...this.fields }

        if (this.uploadFile && typeof this.uploadFile === 'object') {
          // if typeof this.uploadFile === string, it means a picture is set and is untouched
          data.club_picture = this.uploadFile
        } else if (this.club.is_club_pic_uploaded && !this.uploadFile) {
          // the current picture has been cleared
          data.is_club_pic_uploaded = false
        }

        this.$emit('submit', data)
      }
    },

    /**
     * Show an error message if the postcode is invalid.
     * @param {Boolean} force whether to force to display the error message.
     */
    showPostCodeInvalidError(force = false) {
      if (!this.isPostcodeValid || force) {
        if (this.$refs.form) {
          this.$refs.form.setErrors({
            postcode: this.$t('phrase.no_town_found'),
          })
        } else {
          this.$notify({
            message: this.$t('phrase.no_town_found'),
            type: 'error',
          })
        }
      } else if (this.$refs.form) {
        this.$refs.form.setErrors({ postcode: null })
      }
    },
  },
}
