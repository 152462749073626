
import { ACCOUNT_LINKING_ERROR_CODES } from '~/models/enums'
import { isEmailUpdatable } from '~/models/player'
import { getMessageFromErrorResponse } from '~/utils/data'

export default {
  emits: ['next', 'setOptions'],

  fetch() {
    return this.fetchUser()
  },

  data() {
    const user = this.$auth.user
    return {
      fields: {
        email: null,
        phone_number: null,
        ...user,
      },
      loading: false,
    }
  },

  computed: {
    club() {
      return this.$store.getters['clubs/getSelected']
    },

    promptEmail() {
      if (isEmailUpdatable(this.user)) {
        return true
      }

      /**
       * If user authenticated with facebook or apple, the email can be changed only if none is set
       * (facebook can return user profile without email)
       */
      return this.user && !this.user.email
    },

    user() {
      return this.$auth.user
    },
  },

  watch: {
    user(val) {
      this.fields = { ...this.fields, ...val }
    },
  },

  methods: {
    fetchUser() {
      return this.$auth.fetchUser()
    },

    async handleSubmit() {
      this.loading = true

      try {
        const payload = {
          id: this.user.id,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          phone_number: this.fields.phone_number,
        }

        if (this.promptEmail) {
          payload.email = this.fields.email
        }

        await this.$store.dispatch('players/update', payload)
        this.fetchUser() // refresh user and update authenticated user

        // hide step once completed
        this.$emit('setOptions', { condition: false, required: false })
        // go to next step if any
        this.$emit('next')
      } catch (error) {
        this.$notify({
          message: getMessageFromErrorResponse(error, {
            409: 'error.user_account.email_already_used',
            422: `error.user_account.account_with_this_email.${
              error.response?.status === 422 &&
              error.response.data?.code ===
                ACCOUNT_LINKING_ERROR_CODES.EMAIL_TAKEN_BY_ACCOUNT_WITH_APPLE_CRED
                ? 'apple'
                : 'facebook'
            }`,
            423: 'phrase.error_no_email_facebook_or_apple',
          }),
          type: 'error',
        })

        this.$emit('setOptions', { required: false })
      } finally {
        this.loading = false
      }
    },
  },
}
