
export default {
  computed: {
    attrs() {
      return {
        exact: true,
        nuxt: true,
        to: {
          name: 'clubs-create',
        },
      }
    },
  },

  /**
   * This component is a single wrapper to inject some properties.
   * No additional DOM element is rendered except child.
   */
  render() {
    return this.$scopedSlots.default({ attrs: this.attrs })
  },
}
