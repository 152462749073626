import { request } from '~/utils/request'

/* eslint-disable camelcase */
/**
 * Remove values that should not be sent to the server when creating/updating a player.
 * @param {Object} data the player data.
 * @returns {Object}
 */
const sanitizePlayer = ({
  first_name,
  last_name,
  locale_id,
  is_allowing_newsletter,
  email,
  is_profile_pic_uploaded,
  gender_id,
  date_of_birth,
  mailing_address,
  phone_number,
}) => ({
  first_name,
  last_name,
  locale_id,
  is_allowing_newsletter,
  email,
  is_profile_pic_uploaded,
  gender_id,
  date_of_birth,
  mailing_address,
  phone_number,
})

/* eslint-enable camelcase */

export const actions = {
  /**
   * List all players.
   * @returns {Promise<Array>}
   */
  async fetchList(
    _,
    {
      clubId,
      isClubAdmin,
      includeSelf,
      perPage,
      nextPageToken,
      page,
      q,
      teamId,
    }
  ) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/members',
      { clubId },
      {
        include_self: includeSelf,
        is_club_admin: isClubAdmin,
        next_page_token: nextPageToken,
        page,
        per_page: perPage,
        q,
        team_id: teamId,
      }
    )
    return data
  },

  /**
   * List all awaiting players.
   * @returns {Promise<Array>}
   */
  async fetchAwaitingList(
    _,
    { clubId, isClubAdmin, nextPageToken, page, perPage, q, teamId }
  ) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/awaiting-members',
      { clubId },
      {
        is_club_admin: isClubAdmin,
        next_page_token: nextPageToken,
        page,
        per_page: perPage,
        q,
        team_id: teamId,
      }
    )
    return data
  },

  /**
   * List all players from team.
   */
  async fetchListFromTeam(_, { id }) {
    const { data } = await request(this).get('/teams/{teamId}/members', {
      teamId: id,
    })
    return data
  },

  /**
   * List members overview from a team.
   * @param {Number} param1 the team ID.
   * @returns {Array}
   */
  async fetchListOverviewFromTeam(_, { id }) {
    const { data } = await request(this).get(
      '/teams/{teamId}/members-overview',
      {
        teamId: id,
      }
    )
    return data
  },

  /**
   * Retrieve a player by its ID.
   * @param {Number} playerId the player unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchOne(_, { playerId }) {
    const { data } = await request(this).get('/players/{playerId}', {
      playerId,
    })
    return data
  },

  /**
   * Fetch a player in a club context.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} playerId the player unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchOneFromClub(_, { clubId, playerId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/players/{playerId}',
      {
        clubId,
        playerId,
      }
    )
    return data
  },

  /**
   * Update a player.
   * @param {Object} values the club values.
   * @returns {Promise<Object>} the updated player.
   */
  async update({ dispatch }, values) {
    let payload = sanitizePlayer(values)

    if (values.profile_picture) {
      const imageData = await dispatch(
        'files/uploadPlayerProfilePicture',
        { image: values.profile_picture, uuid: values.profile_picture_id },
        { root: true }
      )
      payload = { ...payload, ...imageData }
    }

    const { data } = await request(this).put(
      '/players/{playerId}',
      { playerId: values.id },
      payload
    )
    return data
  },
}
