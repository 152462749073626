import { PLAYER_TYPE } from './enums'
import { buildPictureUrl } from '~/utils/data'

export const DEFAULT_PROFILE_PICTURE_URL = '/svg/default-profile-pic-border.svg'
export const DEFAULT_CHILD_PROFILE_PICTURE_URL =
  '/svg/default-child-profile-pict-border.svg'

/**
 * Whether if the email of a player can be updated.
 * @param {Object} player the player.
 * @returns {Boolean}
 */
export const isEmailUpdatable = (player) =>
  player &&
  player.has_local_credentials &&
  !player.has_facebook_credentials &&
  !player.has_apple_credentials

/**
 * Whether if a player is a child.
 * @param {Object} player
 * @returns {Boolean}
 */
export const isChildPlayer = (player) =>
  player?.player_type === PLAYER_TYPE.CHILD

/**
 * Get profile picture of a player.
 * If the player has no picture, the default will be returned.
 * @param {Object} player the player.
 * @returns {Object} the picture data.
 */
export const getPlayerPictureData = (player) => {
  const data = {
    default: DEFAULT_PROFILE_PICTURE_URL,
    defaultChild: DEFAULT_CHILD_PROFILE_PICTURE_URL,
    isChild: false,
    url: null,
  }

  if (player?.is_profile_pic_uploaded) {
    data.url = buildPictureUrl(player.profile_pic_url, player.updated_at)
  } else if (isChildPlayer(player)) {
    data.isChild = true
  }

  return data
}
