
/**
 * Show a customizable notification.
 *
 * @example
 *
 * Simple notification:
 * ```
 * this.$notify({ message: 'This is a notification', type: 'info' })
 * ```
 *
 * This will output an error notification, with color and icon.
 * ```
 * .catch(e => this.$notify({ error: e }))
 * ```
 *
 */
import { events } from './events'

/**
 * A list of default options.
 * @var {Object} defaultOptions
 * @see https://vuetifyjs.com/en/api/v-snackbar/#props
 */
const defaultOptions = {
  ...{
    // snackbar properties
    timeout: 5000, // set -1 for no timeout
    top: true,
  },
  ...{
    // custom properties
    closeable: false,
    details: null,
    error: undefined,
    id: undefined,
    icon: undefined,
    message: undefined,
    open: false,
    text: null,
    type: 'error', // success, info, warning, error
  },
}

export default {
  data() {
    return {
      notifications: [], // a list of notifications to display (can display many at the same moment)
    }
  },
  computed: {
    /**
     * List visible notifications.
     * @returns {Array}
     */
    openNotifications() {
      return this.notifications.filter((notif) => notif.open)
    },
  },

  beforeMount() {
    events.$on('notify', this.handleNotify)
  },

  methods: {
    /**
     * Close a notification.
     * @param {Object} notification the notification to close.
     */
    closeNotification(notification) {
      notification.open = false
    },

    /**
     * Format error message from an http error.
     * @param {Object} error the error.
     * @return {Object} the error message with details if any.
     */
    formatError(error) {
      let text, details

      const body = error?.response?.data
      if (body) {
        if (body.exception) {
          text = this.$t(`error.${body.exception}`)

          if (body.message) {
            details = this.$t(body.message)
          }
        } else if (body.error) {
          text = this.$t(body.error)
        } else if (body.message) {
          text = this.$t(body.message)
        }

        return { text, details }
      }

      return { text: error.message }
    },

    /**
     * Format text message, depending on properties received.
     * @param {Object} props a list of properties to apply to notification.
     * @return {Object} the error message with details if any.
     */
    formatText(props) {
      const { error, message } = props

      if (error) {
        return this.formatError(error)
      }

      return { text: this.$t(message) }
    },

    /**
     * Get notification color and icon, depending on properties received.
     * The color is determined from properties `type` and `color`.
     * The icon is determined from properties `type` and `icon`.
     * @param {Object} props a list of properties to apply to notification.
     * @returns {Object} the notification color and icon.
     * @see https://vuetifyjs.com/en/styles/colors/
     */
    getColorAndIcon(props) {
      let color, icon
      const { color: colorProp, type: typeProp } = props

      if (colorProp) {
        color = colorProp
      }

      switch (typeProp) {
        case 'success':
        case 'info':
        case 'warning':
        case 'error':
          if (!color) {
            color = typeProp
          }
          // if (!icon && iconProp !== false) {
          //   icon = defaultIcons[typeProp]
          // }
          break

        default:
          throw new Error(`Unknown notification type ${typeProp}`)
      }

      return { color, icon }
    },

    /**
     * Get offset for notifications stacking.
     * Handle notification position (top or bottom).
     * @param {Object} notification the notification.
     * @param {Number} index the position of the notification in the stack.
     * @return {String} a css rule to apply to the notification.
     */
    getCssOffset(notification, index) {
      const css = {}

      if (notification.top) {
        css['margin-top'] = `${index * 60}px`
      } else {
        css['margin-bottom'] = `${index * 60}px`
      }

      /**
       * TODO: handle multiline notifications increasing height (see `details` attribute)
       * Maybe have a look to https://github.com/Aymkdn/v-snackbars.
       * I don't use it right now because all the notifications have the same properties (color).
       * In our app we can have an error and an info notifications at the same time.
       */

      return css
    },

    /**
     * Open a notification.
     * @param {Object} props a list of properties to apply to notification.
     */
    handleNotify(props = {}) {
      const options = { ...defaultOptions, ...props }
      const notification = {
        ...options,
        id: this.notifications.length,
        ...this.getColorAndIcon(options),
        ...this.formatText(options),
      }

      this.notifications.push(notification)

      this.$nextTick(function () {
        // preserve opening transition, open snackbar once inserted in DOM
        const index = this.notifications.indexOf(notification)
        this.notifications[index].open = true
      })
    },

    /**
     * Sanitize properties forwarded to the snackbar component.
     * @param {Object} notification the notification properties.
     * @returns {Object} the properties to bind to the snackbar.
     */
    sanitizeSnackbarOptions(notification) {
      const {
        closeable,
        details,
        error,
        icon,
        id,
        message,
        open,
        text,
        type,
        ...rest
      } = notification
      return rest
    },
  },
}
