import FileSaver from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import { POST_IMAGE_EXTENSION } from '~/models/files'
import { getFileNameExtension } from '~/utils/data'
import { request } from '~/utils/request'

/**
 * Upload an image in a public bucket, and return its uuid.
 * @returns {Promise<String>}
 */
const uploadImageToPublicBucket = async (
  { dispatch },
  { bucket, image, $s3, uuid }
) => {
  const awsToken = await dispatch('getAwsToken')
  const clientImage = $s3.getClient(awsToken, bucket)
  const uuidToUse = uuid || uuidv4()
  const filename = `${uuidToUse}.${POST_IMAGE_EXTENSION}`

  await dispatch('uploadFileToS3', {
    bucketName: bucket.bucket,
    client: clientImage,
    file: image,
    filename,
    type: image.type,
  })

  return uuidToUse
}

export const actions = {
  /**
   * Generate a credential token for AWS.
   * @returns {Promise<Object>}
   */
  async getAwsToken() {
    const { data } = await request(this).get('/accounts/get-aws-token')

    return data.aws_token // { access_key_id, secret_access_key, session_token }
  },

  /**
   * Download a file.
   */
  downloadFile(_, { filename, url }) {
    FileSaver.saveAs(url, filename)
  },

  /**
   * Get file content from an url.
   * @param {String} url the file url.
   * @returns {Promise<Blob>}
   */
  getBlob(_, { url }) {
    return fetch(url).then((response) => response.blob())
  },

  /**
   * Upload a file to S3.
   * @param {Object} client the AWS client.
   * @param {String} bucketName the bucket to use.
   * @param {Blob} file the file to upload.
   * @param {String} filename the file name.
   * @param {Stirng} folder the folder in which to upload the file.
   */
  async uploadFileToS3(
    _,
    { client, file, bucketName, filename, folder, type }
  ) {
    const path = `${folder || ''}${folder ? '/' : ''}${filename}`

    const input = this.$s3.buildFileUploadObject(bucketName, path, file, type)
    const result = await client.send(input)
    return result
  },

  /**
   * Upload files of a chat message to S3, and format data to be ready to use in chat message payload.
   * @param {Array} documents a list of documents to upload
   * @param {Array} images a list of images to upload.
   * @param {String} folder the folder to use in the bucket.
   * @returns {Promise<Object>}
   */
  async uploadChatRoomFiles(
    { dispatch },
    { documents = [], images = [], folder }
  ) {
    const awsToken = await dispatch('getAwsToken')
    const client = this.$s3.getClient(awsToken, this.$config.mediaAws.chatRoom)

    // files are an array of { blob, localUrl, name, size, type, extension }
    const [imagesData, documentsData] = await Promise.all([
      Promise.all(
        images.map(async (img) => {
          const uuid = uuidv4()
          const extension = img.extension ? `.${img.extension}` : ''
          const filename = `${uuid}${extension}`

          await dispatch('uploadFileToS3', {
            bucketName: this.$config.mediaAws.chatRoom.bucket,
            client,
            file: img.blob,
            filename,
            folder: `${folder}/images`,
            type: img.type,
          })

          return { file_name: filename }
        })
      ),
      Promise.all(
        documents.map(async (doc) => {
          const uuid = uuidv4()
          const extension = doc.extension ? `.${doc.extension}` : ''
          const filename = `${uuid}${extension}`

          await dispatch('uploadFileToS3', {
            bucketName: this.$config.mediaAws.chatRoom.bucket,
            client,
            file: doc.blob,
            filename,
            folder: `${folder}/documents`,
            type: doc.type,
          })

          return {
            file_name: filename,
            display_name: `${doc.name}${extension}`,
            mime_type: doc.type,
            bytes_count: doc.size,
          }
        })
      ),
    ])

    return { images: imagesData, documents: documentsData }
  },

  /**
   * Upload files of a post, and format data to be ready to use in post payload.
   * @param {Array} documents a list of documents to upload
   * @param {Array} images a list of images to upload.
   * @param {Object} video a video to upload.
   * @returns {Promise<Object>}
   */
  async uploadPostFiles(
    { dispatch },
    { documents = [], images = [], video = null }
  ) {
    const awsToken = await dispatch('getAwsToken')
    const clientDocument = this.$s3.getClient(
      awsToken,
      this.$config.mediaAws.wall.document
    )
    const clientImage = this.$s3.getClient(
      awsToken,
      this.$config.mediaAws.wall.image
    )

    // TODO : video upload not available yet
    // const clientVideo = this.$s3.getClient(awsToken, this.$config.mediaAws.wall.video)

    const [documentsData, imagesData, videoData] = await Promise.all([
      Promise.all(
        documents.map(async (doc) => {
          const uuid = uuidv4()
          const extension = getFileNameExtension(doc.name)
          const filename = `${uuid}${extension ? `.${extension}` : ''}`

          await dispatch('uploadFileToS3', {
            bucketName: this.$config.mediaAws.wall.document.bucket,
            client: clientDocument,
            file: doc,
            filename,
            type: doc.type,
          })

          return {
            file_name: filename,
            display_name: doc.name,
            mime_type: doc.type,
            bytes_count: doc.size,
          }
        })
      ),
      Promise.all(
        images.map(async (img) => {
          const uuid = uuidv4()
          const filename = `${uuid}.${POST_IMAGE_EXTENSION}`

          await dispatch('uploadFileToS3', {
            bucketName: this.$config.mediaAws.wall.image.bucket,
            client: clientImage,
            file: img,
            filename,
            type: img.type,
          })

          return { bucket_id: uuid }
        })
      ),
      Promise.resolve(),
      // TODO : video upload not available yet
      /*
        new Promise((resolve, reject) => {
          if (!video) {
            resolve(null)
          }

          const uuid = uuidv4()
          const extension = video.extension ? `.${video.extension}` : ''
          const filename = `${uuid}${extension}`

          dispatch('uploadFileToS3', {
            bucketName: this.$config.mediaAws.wall.bucket,
            client,
            file: video.blob,
            filename,
            folder: 'videos',
            type: video.type,
          })
            .then(() => resolve({ file_name: filename }))
            .catch((err) => reject(err))
        }),
      */
    ])

    return { documents: documentsData, images: imagesData, video: videoData }
  },

  /**
   * Upload a club logo.
   * @param {Blob} image the image to upload.
   * @returns {Promise<Object>}
   */
  async uploadClubPicture(app, { image }) {
    const uuid = await uploadImageToPublicBucket(app, {
      bucket: this.$config.mediaAws.club.image,
      image,
      $s3: this.$s3,
    })

    return { club_picture_id: uuid, is_club_pic_uploaded: true }
  },

  /**
   * Upload a player profile picture.
   * @param {Blob} image the image to upload.
   * @returns {Promise<Object>}
   */
  async uploadPlayerProfilePicture(app, { image, uuid }) {
    await uploadImageToPublicBucket(app, {
      bucket: this.$config.mediaAws.player.profilePicture,
      image,
      $s3: this.$s3,
      uuid,
    })

    return { is_profile_pic_uploaded: true }
  },

  /**
   * Upload a team picture.
   * @param {Blob} image the image to upload.
   * @returns {Promise<Object>}
   */
  async uploadTeamPicture(app, { image, uuid }) {
    const newUuid = await uploadImageToPublicBucket(app, {
      bucket: this.$config.mediaAws.team.image,
      image,
      $s3: this.$s3,
      uuid,
    })

    const data = { is_team_pic_uploaded: true }
    if (!uuid) {
      data.team_picture_id = newUuid
    }

    return data
  },
}
