import Vue from 'vue'
import ReleaseReload from '~/modules/release-reload'

Vue.use(ReleaseReload)

const CHECK_FREQUENCY = 60 * 1000 // in ms

export default function ({ $config, store }, inject) {
  const state = {}

  /**
   * Fetch last build timestamp and compare to the currnet one.
   */
  const check = () => {
    fetch('/current-version')
      .then((response) => {
        const { status } = response
        if (status >= 200 || status < 300) {
          return response.text()
        }

        return Promise.reject(new Error(response))
      })
      .then((data) => {
        const currentCommit = $config.build.commit_id

        store.commit('app/setBuildChecked', {
          checkedAt: Math.floor(Date.now() / 1000),
          isOutdated:
            data &&
            currentCommit &&
            data.length === 40 && // if url returns a commit id (and not an HTML page)
            data !== currentCommit,
        })
      })
      .catch(() => {
        store.commit('app/setBuildChecked', {
          checkedAt: Math.floor(Date.now() / 1000),
          isOutdated: false,
        })
      })
  }

  const isOutdated = () => store.getters['app/isBuildOutdated']

  const launchTimer = () => {
    state.interval = setInterval(check, CHECK_FREQUENCY)
  }

  const stopTimer = () => {
    if (state.interval) {
      clearInterval(state.interval)
      state.interval = null
    }
  }

  inject('build', {
    check,
    isOutdated,
    launchTimer,
    stopTimer,
    check_frequency: CHECK_FREQUENCY,
  })
}
