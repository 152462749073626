
import AppBar from '~/components/layouts/AppBar.vue'
import Menu from '~/components/organisms/menu/Menu.vue'
import GoogleTagManager from '~/components/atoms/GoogleTagManager.vue'

export default {
  components: { AppBar, GoogleTagManager, Menu },

  props: {
    centered: {
      type: Boolean,
      default: false,
    },

    noGutters: {
      type: Boolean,
      default: false,
    },

    showAppBar: {
      type: Boolean,
      default: true,
    },

    showMenu: {
      type: Boolean,
      default: true,
    },

    showPaymentClub: {
      type: Boolean,
      default: false,
    },

    twoColumns: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      clipped: false,
    }
  },

  computed: {
    fluidContainer() {
      return this.$vuetify.breakpoint.lgOnly
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    navDrawerWidth() {
      return this.isMobile ? 280 : 366
    },

    sidebarOpen: {
      get() {
        return this.$store.getters['app/isSidebarOpen']
      },
      set(val) {
        this.$store.commit('app/setSidebarOpen', val)
      },
    },
  },

  /**
   * When the layout changes, the AppLayout component is unmounted and the value `sidebarOpen` of the store is not updated.
   * To fix this problem `sidebarOpen` is changed manually.
   */
  watch: {
    isMobile(val) {
      if (val) {
        this.$store.commit('app/setSidebarOpen', false)
      }
    },
  },

  beforeDestroy() {
    this.$store.commit('app/setSidebarOpen', null)
  },
  /*******************************************************/
}
