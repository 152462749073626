const GenderEnum = {
  WOMAN: 1,
  MAN: 2,
}

export const actions = {
  fetchList() {
    return Promise.resolve([
      {
        id: GenderEnum.WOMAN,
        name_fr: 'Femme',
        name_en: 'Woman',
        name_es: 'Mujer',
        name_de: 'Frau',
        name_it: 'Donna',
        name_pt: 'Mulher',
        position: 0,
      },
      {
        id: GenderEnum.MAN,
        name_fr: 'Homme',
        name_en: 'Man',
        name_es: 'Hombre',
        name_de: 'Mann',
        name_it: 'Uomo',
        name_pt: 'Homem',
        position: 1,
      },
    ])
  },
}
