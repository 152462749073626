import { request } from '~/utils/request'

export const actions = {
  /**
   * Fetch a list of ClubPlayerAnnouncement for the current player.
   * @returns {Promise<Array<Object>>}
   */
  async fetchClubPlayerAnnouncementList(_, { clubId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/announcements/player',
      { clubId }
    )

    return data
  },

  /**
   * Mark some announcements as completed.
   * @returns {Promise<Array<Object>>}
   */
  async markAnnouncementCompleted(_, { clubId, uuids }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/announcements/player',
      { clubId },
      (uuids || []).map((uuid) => ({ component_uuid: uuid }))
    )

    return data
  },
}
