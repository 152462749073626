
export default {
  emits: ['submit'],

  props: {
    labels: {
      type: Object,
      default: () => ({}),
    },

    rules: {
      type: Object,
      default: () => ({}),
    },

    slim: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    /**
     * Emit `submit` event to the parent component.
     */
    raiseSubmitEvent(props) {
      this.$emit('submit', props)
    },

    /**
     * Forward reset action to the form.
     */
    reset() {
      this.$refs.form.reset()
    },

    /**
     * Forward errors to the form.
     * @param {*} props
     */
    setErrors(props) {
      this.$refs.form.setErrors(props)
    },

    /**
     * Trigger form validation.
     */
    async validate() {
      try {
        /**
         * This method will validate the form.
         * @see https://vee-validate.logaretm.com/v3/api/validation-observer.html#methods
         */
        const isValid = await this.$refs.form.$refs.observer.validate()

        if (isValid) {
          /**
           * `raiseSubmitEvent` is not called using @submit="raiseSubmitEvent" on <t-form>, don't know why...
           */
          this.raiseSubmitEvent()
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
