import { request } from '~/utils/request'

/* eslint-disable camelcase */

/**
 * Remove values that should not be sent to the server when creating a payment, and format some values.
 * @param {Object} data the payment data.
 * @returns {Object}
 */
const sanitizePayment = ({
  payment_type_id,
  payment_subject_id,
  amount,
  payment_instructions,
}) => {
  const res = {
    payment_type_id: payment_type_id.id || null,
    payment_type_label:
      typeof payment_type_id === 'string'
        ? payment_type_id
        : typeof payment_type_id === 'object' && payment_type_id.label
        ? payment_type_id.label
        : null,
    payment_subject_id: payment_subject_id.id || null,
    payment_subject_label:
      typeof payment_subject_id === 'string' ? payment_subject_id : null,
    amount: parseFloat(amount) || null,
    payment_instructions,
  }
  return res
}

/**
 * Remove values that should not be sent to the server when creating a payment for third party.
 * @param {Object} data the payment data.
 * @returns {Object}
 */
const sanitizePaymentThirdParty = ({
  payment_third_party_id,
  payment_third_party_email,
  ...rest
}) => {
  const res = {
    ...sanitizePayment(rest),
    payment_third_party_id: payment_third_party_id.id || null,
    payment_third_party_label:
      typeof payment_third_party_id === 'string'
        ? payment_third_party_id
        : null,
    payment_third_party_email,
  }
  return res
}

/**
 * Remove values that should not be sent to the server when creating a payment for players.
 * @param {Object} data the payment data.
 * @returns {Object}
 */
const sanitizePaymentPlayers = ({ players_ids, ...rest }) => {
  const res = {
    ...sanitizePayment(rest),
    players_ids,
  }
  return res
}

/**
 * Remove values that should not be sent to the server when creating/updating a payment.
 * @param {Object} data the payment data.
 * @returns {Object}
 */
const sanitizePaymentStatus = ({
  amount,
  payment_method,
  reference,
  status,
}) => {
  const res = {
    amount: parseFloat(amount) || null,
    status,
  }
  if (payment_method) {
    res.payment_method = payment_method
  }
  if (reference) {
    res.reference = reference
  }

  return res
}
/* eslint-enable camelcase */

export const actions = {
  /**
   * List all payments line.
   * @returns {Promise}
   */
  async fetchList(_, { clubId, filters, page, perPage, sortOrder }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payments',
      { clubId },
      {
        ...filters,
        is_credit:
          typeof filters.is_credit === 'boolean'
            ? Number(filters.is_credit)
            : undefined,
        is_debit:
          typeof filters.is_debit === 'boolean'
            ? Number(filters.is_debit)
            : undefined,
        page,
        per_page: perPage,
        sort: sortOrder,
        payment_type_id: filters.payment_type_id,
        payment_subject_id: filters.payment_subject_id,
        payment_third_party_id: filters.payment_third_party_id,
        payment_method: filters.payment_method,
        created_at_from: filters.created_at_from || null,
        created_at_to: filters.created_at_to || null,
        status: filters.status,
      }
    )

    return data
  },

  /**
   * Create a payment line for third-party.
   * @param {Number} clubId the club id.
   * @param {Object} values the payment values.
   * @returns {Promise<Object>} the created payment.
   */
  async createThirdParty(_, { clubId, values }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/payments/third-party',
      { clubId },
      sanitizePaymentThirdParty(values)
    )

    return data
  },

  /**
   * Create a payment line for players.
   * @param {Number} clubId the club id.
   * @param {Object} values the payment values.
   * @returns {Promise<Object>} the created payment.
   */
  async createPlayersPayment(_, { clubId, values }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/payments/players',
      { clubId },
      sanitizePaymentPlayers(values)
    )

    return data
  },

  /**
   * Search payment lines with a given query.
   * @param {Number} clubId the club Id.
   * @param {String} label the label to search.
   * @returns {Promise<Array>}
   */
  async searchByName(_, { clubId, label }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/payments/search-name',
      { clubId },
      {
        q: label,
      }
    )
    return data
  },

  /**
   * Update a status payment line.
   * @param {Function} commit a function to commit data in the store.
   * @param {Object} state the current state.
   * @param {Number} clubId the club id.
   * @param {Number} paymentId the payment id.
   * @param {Object} values the payment values.
   * @returns {Promise<Object>} the updated payment.
   */
  async updateStatus(_, { clubId, paymentId, values }) {
    const { data } = await request(this, 'club').patch(
      '/clubs/{clubId}/payments/{paymentId}',
      { clubId, paymentId },
      sanitizePaymentStatus(values)
    )

    return data
  },

  /**
   * Delete payment line.
   * @param {Number} clubId the club id.
   * @param {Number} paymentId the payment id.
   * @returns {Promise<Object>} the updated payment.
   */
  async delete(_, { clubId, paymentId }) {
    const { data } = await request(this, 'club').delete(
      '/clubs/{clubId}/payments/{paymentId}',
      { clubId, paymentId }
    )

    return data
  },

  /**
   * Retrieve a pay-in request.
   * @param {Number} paymentId the payment unique identifier.
   * @param {String} token the payment token.
   * @returns {Promise<Object>}
   */
  async getPayInRequest(_, { paymentId, token }) {
    const { data } = await request(this, 'club').get(
      '/payment/{paymentId}/request/token/{token}',
      { paymentId, token }
    )

    return data
  },

  /**
   * Generate a redirect url for a payment.
   * @param {Number} paymentId the payment unique identifier.
   * @param {String} token the payment token.
   * @param {String} email the user email.
   * @returns {Promise<Object>}
   */
  async generatePayInRedirectUrl(_, { paymentId, token, ...props }) {
    const { data } = await request(this, 'club').post(
      '/payment/{paymentId}/request/token/{token}',
      { paymentId, token },
      props
    )

    return data
  },

  /**
   * Retrieve a pay-in request.
   * @param {Number} paymentId the payment unique identifier.
   * @param {String} token the payment token.
   * @returns {Promise<Object>}
   */
  async getPayInResponse(_, { paymentId, token }) {
    const { data } = await request(this, 'club').get(
      '/payment/{paymentId}/response/token/{token}',
      { paymentId, token }
    )

    return data
  },

  /**
   * Send a reminder for a payment.
   */
  async sendReminder(_, { clubId, paymentId }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/payments/{paymentId}/send-reminder',
      { clubId, paymentId }
    )

    return data
  },
}
