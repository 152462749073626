
export default {
  props: {
    maxWidth: {
      type: String,
      default: 'sm',
      validator: (val) => ['lg', 'md', 'sm', 'xl', 'xs', null].includes(val),
    },
  },

  computed: {
    dialogMaxWidth() {
      switch (this.maxWidth) {
        // retrieved from MUI dialog component
        // @see https://v4.mui.com/components/dialogs/#optional-sizes
        case 'xs':
          return Math.max(this.$vuetify.breakpoint.thresholds.xs, 444)
        case 'sm':
          return this.$vuetify.breakpoint.thresholds.xs
        case 'md':
          return this.$vuetify.breakpoint.thresholds.sm
        case 'lg':
          return this.$vuetify.breakpoint.thresholds.md
        case 'xl':
          return this.$vuetify.breakpoint.thresholds.lg

        default:
          // full width
          return undefined
      }
    },
  },
}
