import { request } from '~/utils/request'

export const actions = {
  /**
   * Send a reminder for an event.
   * @param {Number} eventId the event identifier.
   * @param {Number} eventType the event type.
   */
  async create(_, { eventId, eventType }) {
    const { data } = await request(this).post('notifications', null, {
      event_id: eventId,
      event_type: eventType,
    })
    return data
  },
}
