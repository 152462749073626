
import MenuItemsListWrapper from '~/components/organisms/menu/MenuItemsListWrapper.vue'
import { ADMIN_STATUS } from '~/models/enums'

export default {
  components: { MenuItemsListWrapper },

  data() {
    return { adminStatus: ADMIN_STATUS }
  },

  computed: {
    club() {
      return this.$store.getters['clubs/getSelected']
    },
    iconSize() {
      return this.isMobile
        ? {
            height: 28,
            width: 28,
          }
        : {
            height: 32,
            width: 32,
          }
    },
    iconSizeNested() {
      return this.isMobile
        ? {
            height: 24,
            width: 24,
          }
        : {
            height: 28,
            width: 28,
          }
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
