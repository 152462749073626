import { cloneDeep, findIndex, last, orderBy, sortBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { CHAT_MESSAGE_TYPES } from '~/models/chatMessage'
import { request } from '~/utils/request'

export const state = () => ({
  currentUserId: null,
  users: {
    // userId: {
    //   chatRooms: {
    //     list: [],
    //     selectedId: null,
    //     nextPageToken: null,
    //     topPageToken: null,
    //   },
    //   messages: {
    //     chatRoomId: {
    //       fullyLoaded: false,
    //       list: [],
    //       lastUpdateToken: null,
    //       nextPageToken: null,
    //       topPageToken: null,
    //     },
    //   },
    //   unreadMessagesCount: undefined,
    // },
  },
})

/**
 * Initialize chat rooms state for a given user.
 * @param {Object} state the current state.
 * @returns {Number} the user ID.
 */
const initializeChatRoomState = (state) => {
  const playerId = state.currentUserId

  if (!playerId) {
    throw new ReferenceError('Player not set')
  }

  if (!state.users[playerId]) {
    // use cloneDeep to force Vuex to watch new references
    const usersStates = cloneDeep(state.users)
    usersStates[playerId] = {
      chatRooms: {
        list: [],
        nextPageToken: null,
        selectedId: null,
        topPageToken: null,
      },
      messages: {},
      unreadMessagesCount: undefined,
    }
    state.users = usersStates
  }

  return playerId
}

/**
 * Initialize a messages state for a given user and chat room.
 * @param {Object} state the current state.
 * @param {*} chatRoomId the chat room to initialize.
 * @returns {Number} the user ID.
 */
const initializeChatRoomMessageState = (state, chatRoomId) => {
  const playerId = initializeChatRoomState(state)

  if (!state.users[playerId].messages[chatRoomId]) {
    // use cloneDeep to force Vuex to watch new references
    const messagesState = cloneDeep(state.users[playerId].messages)
    messagesState[chatRoomId] = {
      fullyLoaded: false,
      list: [],
      lastUpdateToken: null,
      nextPageToken: null,
      topPageToken: null,
    }
    state.users[playerId].messages = messagesState
  }

  return playerId
}

export const actions = {
  /**
   * Add players to an exeisting chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Array<Number>} playersIds the players ids
   * @returns {Promise<Array<Object>>}
   */
  async addChatRoomPlayers({ commit }, { chatRoomId, playersIds }) {
    const { data } = await request(this, 'chat').post(
      '/chat-rooms/{chatRoomId}/players',
      { chatRoomId },
      { players_ids: playersIds }
    )

    return data
  },

  /**
   * Archive or unarchive a chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Boolean} isArchived whether to archive or unarchive the chat room.
   * @returns {Promise<Object>}
   */
  async archiveChatRoom({ commit }, { chatRoomId, isArchived }) {
    const { data } = await request(this, 'chat').put(
      '/chat-rooms/{chatRoomId}/archive',
      { chatRoomId },
      { is_archived: isArchived }
    )

    commit('upsertChatRooms', data)

    return data
  },

  /**
   * Create a chat room.
   * @param {Object} values the chat room values.
   * @returns {Promise<Object>}
   */
  async createChatRoom({ commit }, values) {
    const { data } = await request(this, 'chat').post(
      '/chat-rooms',
      null,
      values
    )

    commit('addChatRooms', data)

    return data
  },

  /**
   * Create a chat room for an event.
   * @param {Object} values the chat room values.
   * @returns {Promise<Object>}
   */
  async createChatRoomForEvent({ commit }, values) {
    const { data } = await request(this, 'chat').post(
      '/create-event-chat-rooms',
      null,
      values
    )

    commit('addChatRooms', data)

    return data
  },

  /**
   * Create a new chat message.
   * @param {Number} chatRoomId the chat room ID.
   * @param {String} text the message content.
   * @param {Number} replyToId the message user is replying to.
   * @param {Array<Object>} images
   * @param {Array<Object>} documents
   */
  async createMessage(
    { commit, dispatch, getters },
    { chatRoomId, text, replyToId: replyToMessage, images, documents, folderId }
  ) {
    let textMessagePayload, tmpTextMessage
    let imageMessagePayload, tmpImageMessage
    let documentMessagePayload, tmpDocumentMessage

    const tmpMessage = {
      creation_date: new Date().toISOString(),
      player_id: getters.getCurrentUserId,
      player: {},
      ...(replyToMessage
        ? {
            reply_to_message_id: replyToMessage.id,
            reply_to_message: {
              ...replyToMessage,
              player_id: getters.getCurrentUserId,
            },
          }
        : {}),
    }

    if (text) {
      textMessagePayload = {
        message_uuid: uuidv4(),
        text,
        reply_to_message_id: replyToMessage ? replyToMessage.id : undefined,
      }
      tmpTextMessage = {
        ...textMessagePayload,
        ...tmpMessage,
        id: textMessagePayload.message_uuid,
        message_type: CHAT_MESSAGE_TYPES.TEXT_MESSAGE,
      }
    }

    // files are an array of { blob, localUrl, name, size, type, extension }
    if (images && images.length) {
      imageMessagePayload = {
        message_uuid: uuidv4(),
        text: '',
        reply_to_message_id: replyToMessage ? replyToMessage.id : undefined,
      }
      tmpImageMessage = {
        ...imageMessagePayload,
        ...tmpMessage,
        id: imageMessagePayload.message_uuid,
        images: await Promise.all(
          images.map(async ({ blob, localUrl, name, type }) => {
            const preview = await new Promise((resolve) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.readAsDataURL(blob)
            })

            return {
              file_name: name,
              mime_type: type,
              url: localUrl,
              preview,
            }
          })
        ),
        message_type: CHAT_MESSAGE_TYPES.IMAGE_MESSAGE,
      }
    }

    if (documents && documents.length) {
      documentMessagePayload = {
        message_uuid: uuidv4(),
        text: '',
        reply_to_message_id: replyToMessage ? replyToMessage.id : undefined,
      }
      tmpDocumentMessage = {
        ...documentMessagePayload,
        ...tmpMessage,
        id: documentMessagePayload.message_uuid,
        documents: documents.map(({ localUrl, name, size, type }) => ({
          file_name: name,
          bytes_count: size,
          mime_type: type,
          url: localUrl,
        })),
        message_type: CHAT_MESSAGE_TYPES.DOCUMENT_MESSAGE,
      }
    }

    let messagesPayloads = [textMessagePayload]
    const tmpMessages = [
      tmpTextMessage,
      tmpImageMessage,
      tmpDocumentMessage,
    ].filter(Boolean)

    if (!tmpMessages.length) {
      return
    }

    // insert temporary message in state for optimistic rendering
    commit('addChatRoomMessages', {
      chatRoomId,
      messages: tmpMessages,
    })

    try {
      if ((images && images.length) || (documents && documents.length)) {
        const { images: imagesData, documents: documentsData } = await dispatch(
          'files/uploadChatRoomFiles',
          { images, documents, folder: folderId },
          { root: true } // `root:true` to call action of another module
        )

        if (imageMessagePayload && imagesData) {
          imageMessagePayload.images = imagesData
          messagesPayloads.push(imageMessagePayload)
        }

        if (documentMessagePayload && documentsData) {
          documentMessagePayload.documents = documentsData
          messagesPayloads.push(documentMessagePayload)
        }
      }

      messagesPayloads = messagesPayloads.filter(Boolean)

      if (!messagesPayloads.length) {
        return
      }

      const responses = await Promise.all(
        messagesPayloads.map((messagePayload) =>
          request(this, 'chat')
            .post(
              '/chat-rooms/{chatRoomId}/messages',
              { chatRoomId },
              messagePayload
            )
            .then(({ data }) => data)
        )
      )

      /**
       * Do not insert the new message in the messages list of the chat room.
       * Chat client will receive an event to load this message.
       *
       * Insert message as room last message.
       */

      return responses
    } catch (error) {
      // remove temporary message from state
      dispatch('removeChatRoomMessages', {
        chatRoomId,
        uuids: tmpMessages.map((msg) => msg.message_uuid),
      })

      throw error
    }
  },

  /**
   * Delete a chat message.
   * The message will marked as deleted but not removed from the list.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Number} id the ID of the message to delete.
   */
  async deleteMessage({ commit, getters }, { chatRoomId, id }) {
    const initialMessage = getters.getChatRoomMessage(chatRoomId, id)

    try {
      if (initialMessage) {
        commit('upsertChatRoomsMessages', {
          chatRoomId,
          messages: [{ ...initialMessage, is_deleted: true }],
        })
      }

      const { data } = await request(this, 'chat').delete(
        '/chat-rooms/{chatRoomId}/messages/{messageId}',
        { chatRoomId, messageId: id }
      )

      commit('upsertChatRoomsMessages', {
        chatRoomId,
        messages: [data],
      })
    } catch (error) {
      if (initialMessage) {
        commit('upsertChatRoomsMessages', {
          chatRoomId,
          messages: [{ ...initialMessage, is_deleted: false }],
        })
      }
      throw error
    }
  },

  /**
   * Remove a player from a chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Number} roomId the room ID.
   * @returns {Promise}
   */
  async deleteChatRoomPlayer(_, { chatRoomId, playerId }) {
    const { data } = await request(this, 'chat').delete(
      '/chat-rooms/{chatRoomId}/players/{playerId}',
      { chatRoomId, playerId }
    )

    return data
  },

  /**
   * List archived chat rooms.
   * @returns {Promise<Array<Object>>}
   */
  async fetchArchivedChatRooms({ getters }) {
    const { data } = await request(this, 'chat').get(
      '/players/{playerId}/archived-chat-rooms',
      {
        playerId: getters.getCurrentUserId,
      }
    )

    return data
  },

  /**
   * Fetch a chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @returns {Promise<Object>}
   */
  async fetchChatRoom({ commit }, chatRoomId) {
    const { data } = await request(this, 'chat').get(
      '/chat-rooms/{chatRoomId}',
      { chatRoomId }
    )

    commit('upsertChatRooms', data)

    return data
  },

  /**
   * List messages in a chat room.
   * @returns {Promise<Array<Object>>}
   */
  async fetchChatRoomMessages(
    { commit, dispatch, getters },
    { chatRoomId, nextPageToken } = {}
  ) {
    const isStateInitialized = Array.isArray(
      getters.getChatRoomMessagesList(chatRoomId)
    )

    const queryParams = {
      top_page_token:
        isStateInitialized && !nextPageToken
          ? getters.getChatRoomMessagesTopPageToken(chatRoomId)
          : undefined,
      next_page_token: nextPageToken,
    }

    const { data } = await request(this, 'chat').get(
      '/chat-rooms/{chatRoomId}/messages',
      { chatRoomId },
      queryParams
    )

    if (isStateInitialized && !data.should_client_cache_be_reseted) {
      commit('upsertChatRoomsMessages', {
        chatRoomId,
        messages: data.messages,
      })
      commit('setChatRoomMessagesNextPageToken', {
        chatRoomId,
        token: data.next_page_token,
      })
      commit('setChatRoomMessagesTopPageToken', {
        chatRoomId,
        token: data.top_page_token,
      })

      if (
        !nextPageToken &&
        data.last_update_token !==
          getters.getChatRoomMessagesLastUpdateToken(chatRoomId)
      ) {
        await dispatch('fetchLastUpdatedMessages', {
          chatRoomId,
          token: data.last_update_token,
        })
      }
    } else {
      commit('setChatRoomMessages', {
        chatRoomId,
        messages: data.messages,
      })
      commit('setChatRoomMessagesNextPageToken', {
        chatRoomId,
        token: data.next_page_token,
      })
      commit('setChatRoomMessagesTopPageToken', {
        chatRoomId,
        token: data.top_page_token,
      })
      commit('setChatRoomMessagesLastUpdateToken', {
        chatRoomId,
        token: data.last_update_token,
      })
    }

    return data
  },

  /**
   * Fetch members of a chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @returns {Promise<Array<Object>>}
   */
  async fetchChatRoomPlayers(_, { chatRoomId }) {
    const { data } = await request(this, 'chat').get(
      '/chat-rooms/{chatRoomId}/players',
      { chatRoomId }
    )

    return data
  },

  /**
   * List chat rooms a player can access.
   * @returns {Promise} a chat room list. The response data will be stored in state.
   */
  async fetchChatRooms({ commit, getters }, { nextPageToken } = {}) {
    const playerId = getters.getCurrentUserId
    const isStateInitialized = getters.isUserStateInitialized

    const topPageToken =
      isStateInitialized && !nextPageToken
        ? getters.getChatRoomTopPageToken
        : undefined
    const queryParams = {
      top_page_token: topPageToken,
      next_page_token: nextPageToken,
    }

    const { data } = await request(this, 'chat').get(
      '/players/{playerId}/chat-rooms',
      { playerId },
      queryParams
    )

    if (isStateInitialized && data.should_client_cache_be_reseted) {
      commit('setChatRooms', [])
      commit('setChatRoomsNextPageToken', data.next_page_token)
      commit('setChatRoomsTopPageToken', data.top_page_token)
    }

    commit('upsertChatRooms', data.chat_rooms)

    if (!topPageToken) {
      commit('setChatRoomsNextPageToken', data.next_page_token)
    }

    if (
      !nextPageToken &&
      data.top_page_token &&
      data.top_page_token !== 'end'
    ) {
      commit('setChatRoomsTopPageToken', data.top_page_token)
    }

    return data.chat_rooms
  },

  /**
   * Get the chat room which is used for sending direct messages between the two players.
   * @param {Number} playerId the player requesting the chat room.
   * @param {Number} recipientId the target player of the chat room.
   * @returns {Promise<Object>}
   */
  async fetchDirectChatRoom({ getters }, { recipientId }) {
    const { data } = await request(this, 'chat').get(
      '/players/{playerId}/dm-recipient/{recipientId}',
      {
        playerId: getters.getCurrentUserId,
        recipientId,
      }
    )
    return data
  },

  /**
   * Fetch last messages updates.
   * @param {Number} chatRoomId the chat room ID.
   * @param {String} token the last update token.
   * @returns {Promise}
   */
  async fetchLastUpdatedMessages(
    { commit, dispatch, getters },
    { chatRoomId, token }
  ) {
    const lastUpdateToken =
      token || getters.getChatRoomMessagesLastUpdateToken(chatRoomId)

    if (!lastUpdateToken) {
      return
    }

    const { data } = await request(this, 'chat').get(
      '/chat-rooms/{chatRoomId}/last-updated-messages/{lastUpdateToken}',
      {
        chatRoomId,
        lastUpdateToken,
      }
    )

    dispatch('removeChatRoomMessages', {
      chatRoomId,
      ids: data.deleted_messages_ids,
      softRemove: true,
    })
    commit('setChatRoomMessagesLastUpdateToken', {
      chatRoomId,
      token: data.last_update_token,
    })
  },

  /**
   * Fetch the number of unread messages.
   * @returns {Promise<Number>}
   */
  async fetchUnreadMessagesCount({ commit }) {
    const { data } = await request(this).get('/unread-messages-count')

    commit('setUnreadMessagesCount', data.badges_count)

    return data.badges_count
  },

  /**
   * Retrieve a direct message chat room, and create it if it doesn't exist yet.
   * @param {Number} playerId the player unique identifier.
   * @param {Number|undefined} currentPlayerId the current authenticated player identifier. Will be retrieved automatically if not set.
   * @param {Boolean} [unarchiveIfArchived=true] whether to automatically unarchive the chat room if archived.
   * @returns {Promise<Array<Object, Boolean>>} the chat room, and a boolean to know if it has been created or already existed.
   */
  async getOrCreateDirectChatRoom(
    { dispatch },
    { playerId, currentPlayerId = undefined, unarchiveIfArchived = true }
  ) {
    let chatRoom
    let isNew

    if (!currentPlayerId) {
      currentPlayerId = getters.getCurrentUserId
    }

    try {
      chatRoom = await dispatch('createChatRoom', {
        name: String(playerId),
        players_ids: [playerId],
      })
      isNew = true
    } catch (error) {
      if (error.response?.status !== 409) {
        throw error
      }

      chatRoom = await dispatch('fetchDirectChatRoom', {
        playerId: currentPlayerId,
        recipientId: parseInt(playerId),
      })
      isNew = false
    }

    if (
      chatRoom.current_player_membership?.is_archived &&
      unarchiveIfArchived
    ) {
      try {
        await dispatch('archiveChatRoom', {
          chatRoomId: chatRoom.id,
          isArchived: false,
        })
      } catch (error) {
        /**
         * Error 409 means the chat room contains no message.
         * Should not be archived, so ignore it.
         */
        if (error.response?.status !== 409) {
          throw error
        }
      }
    }

    return [chatRoom, isNew]
  },

  /**
   * Mark a chat room as read, and update chat room in store.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Number} messageId the ID of the last message read.
   * @returns {Promise<Object>}
   */
  async markChatRoomRead({ commit, dispatch }, { chatRoomId, messageId }) {
    const { data } = await request(this, 'chat').put(
      '/chat-rooms/{chatRoomId}/mark-as-read',
      { chatRoomId },
      { last_message_id: messageId }
    )

    commit('upsertChatRooms', data)
    dispatch('fetchUnreadMessagesCount')

    return data
  },

  /**
   * Mute or unmute a chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Boolean} isMuted whether to mute or unmute.
   * @returns {Promise<Object>}
   */
  async muteChatRoom({ commit }, { chatRoomId, isMuted }) {
    const { data } = await request(this, 'chat').put(
      '/chat-rooms/{chatRoomId}/mute',
      { chatRoomId },
      { is_muted: isMuted }
    )

    commit('upsertChatRooms', data)

    return data
  },

  /**
   * Remove messages from a chat room.
   * @param {Number} chatRoomId the chat room for which to remove messages.
   * @param {Array<Object>|Object} messages the messages to remove.
   * @param {Array<Number>} ids the IDs of the messages to remove.
   * @param {Array<String>} uuids the UUIDs of the messages to remove.
   * @param {Boolean} softRemove whether to apply "is_deleted" flag or deep remove it from the list.
   */
  removeChatRoomMessages(
    { commit, getters },
    { chatRoomId, messages, ids, uuids, softRemove = false }
  ) {
    const messagesIds =
      ids ||
      (Array.isArray(messages) ? messages.map((message) => message.id) : [])
    const messagesUuids = uuids || []

    if (messagesIds.length + messagesUuids.length) {
      const roomMessages = getters.getChatRoomMessagesList(chatRoomId)

      if (softRemove) {
        const softDeletedMessages = roomMessages
          .filter(
            (message) =>
              messagesIds.includes(message.id) ||
              messagesUuids.includes(message.message_uuid)
          )
          .map((message) => ({ ...message, is_deleted: true }))

        commit('upsertChatRoomsMessages', {
          chatRoomId,
          messages: softDeletedMessages,
        })
      } else {
        const newList = roomMessages.filter(
          (message) =>
            !(
              messagesIds.includes(message.id) ||
              messagesUuids.includes(message.message_uuid)
            )
        )
        commit('setChatRoomMessages', { chatRoomId, messages: newList })
      }
    }
  },

  /**
   * Synchronise chat room members.
   * Available only for a chat room linked to an event.
   * The updated chat room will be saved in state.
   * @param {Number} chatRoomId the chat room ID.
   * @returns {Object} the updated chat room.
   */
  async syncChatRoomMembers({ commit }, { chatRoomId }) {
    const { data } = await request(this, 'chat').post(
      '/chat-rooms/{chatRoomId}/sync-members',
      { chatRoomId },
      { chatRoomId }
    )

    commit('upsertChatRooms', data)

    return data
  },

  /**
   * Update chat room.
   * @param {Number} id the chat room ID.
   * @param {String} name the chat room name.
   * @returns {Promise<Object>}
   */
  async updateChatRoom({ commit }, { id, name }) {
    const { data } = await request(this, 'chat').put(
      '/chat-rooms/{chatRoomId}',
      { chatRoomId: id },
      { name }
    )

    commit('upsertChatRooms', data)

    return data
  },

  /**
   * Update the admin rights of a player in a chat room.
   * @param {Number} chatRoomId the chat room ID.
   * @param {Number} playerId the player ID.
   * @returns {Promise<Object>}
   */
  async updateChatRoomPlayer(_, { chatRoomId, playerId, isAdmin }) {
    const { data } = await request(this, 'chat').put(
      '/chat-rooms/{chatRoomId}/players/{playerId}',
      { chatRoomId, playerId },
      { is_admin: isAdmin }
    )

    return data
  },
}

/**
 * Retrieve a chat room token.
 * @param {Object} state the current state.
 * @param {String} tokenName the token to get.
 * @returns {String}
 */
const getChatRoomToken = (state, tokenName) => {
  return state.users[state.currentUserId]?.chatRooms[tokenName]
}

/**
 * Set a token in the state.
 * If user state is not defined, it will be initialized.
 * @param {Object} state the current state.
 * @param {String} tokenName the token to set.
 * @param {String} token the token value. If equals "end", the value will be replaced by `null`.
 */
const setChatRoomToken = (state, tokenName, token) => {
  const playerId = initializeChatRoomState(state)
  state.users[playerId].chatRooms[tokenName] = token === 'end' ? null : token
}

/**
 * Get a messages list token.
 * @param {Object} state the current state.
 * @param {*} chatRoomId the messages chat room.
 * @param {String} tokenName the token to get.
 * @returns {String}
 */
const getChatRoomMessageToken = (state, chatRoomId, tokenName) => {
  try {
    return state.users[state.currentUserId].messages[chatRoomId][tokenName]
  } catch (error) {
    return undefined
  }
}

/**
 * Set a token in the state.
 * If user state is not defined, it will be initialized.
 * @param {Object} state the current state.
 * @param {*} chatRoomId the messages chat room.
 * @param {String} tokenName the token to set.
 * @param {String} token the token value. If equals "end", the value will be replaced by `null`.
 */
const setChatRoomMessageToken = (state, chatRoomId, tokenName, token) => {
  const playerId = initializeChatRoomMessageState(state, chatRoomId)

  state.users[playerId].messages[chatRoomId][tokenName] =
    token === 'end' ? null : token
}
/**
 * Set chat room messages list as fully loaded if receiving a next page token with value "end".
 * @param {Object} state the current state.
 * @param {*} chatRoomId the messages chat room.
 * @param {*} nextPageToken the next page token received from API.
 */
const setChatRoomMessagesFullyLoaded = (state, chatRoomId, nextPageToken) => {
  if (nextPageToken === 'end') {
    const playerId = initializeChatRoomMessageState(state, chatRoomId)
    state.users[playerId].messages[chatRoomId].fullyLoaded = true
  }
}

export const getters = {
  /**
   * Get current user ID.
   * @param {Object} state the current state.
   * @returns {Number}
   */
  getCurrentUserId(state) {
    return state.currentUserId
  },
  /**
   * Retrieve a chat room.
   * @param {Object} state the current state.
   * @returns {Function}
   */
  getChatRoom: (state) => (chatRoomId) => {
    return state.users[state.currentUserId]?.chatRooms.list.find(
      (room) => room.id === chatRoomId
    )
  },
  /**
   * Get the list of chat rooms.
   * @param {Object} state the current state.
   * @returns {Array<Object>}
   */
  getChatRoomsList(state) {
    return (state.users[state.currentUserId]?.chatRooms?.list || []).filter(
      (room) => {
        const isDeleted = room.is_deleted
        const isArchived = room.current_player_membership?.is_archived

        return !isDeleted && !isArchived
      }
    )
  },
  /**
   * Get the next page token.
   * @param {Object} state the current state.
   * @returns {String}
   */
  getChatRoomsNextPageToken(state) {
    return getChatRoomToken(state, 'nextPageToken')
  },
  /**
   * Get the current selected chat room.
   * @param {Object} state the current state.
   * @returns {Object}
   */
  getSelectedChatRoom(state) {
    const selectedId = state.users[state.currentUserId]?.chatRooms?.selectedId

    if (selectedId) {
      return state.users[state.currentUserId].chatRooms.list.find(
        (room) => room.id === selectedId
      )
    }

    return undefined
  },
  /**
   * Get the top page token.
   * @param {Object} state the current state.
   * @returns {String}
   */
  getChatRoomTopPageToken(state) {
    return getChatRoomToken(state, 'topPageToken')
  },

  /**
   * Get messages for a given chat room.
   * @param {Object} state the current state.
   * @returns {Function}
   */
  getChatRoomMessagesList: (state) => (chatRoomId) => {
    return state.users[state.currentUserId]?.messages[chatRoomId]?.list || []
  },
  /**
   * Get a single chat message.
   * @param {Object} state the current state.
   * @returns {Function}
   */
  getChatRoomMessage: (state) => (chatRoomId, id) => {
    return (
      state.users[state.currentUserId]?.messages[chatRoomId]?.list || []
    ).find((message) => message.id === id)
  },
  /**
   * Get the last message written in a chat room.
   * @param {Object} state the current state.
   * @returns {Function}
   */
  getChatRoomLastMessage: (state) => (chatRoomId) => {
    const lastMessageFromMessagesList = last(
      state.users[state.currentUserId]?.messages[chatRoomId]?.list || []
    )
    const chatRoomLastMessage = (
      state.users[state.currentUserId]?.chatRooms.list || []
    ).find((room) => room.id === chatRoomId).last_message

    const messages = sortBy(
      [lastMessageFromMessagesList, chatRoomLastMessage].filter(Boolean),
      ['creation_date']
    )

    return last(messages)
  },
  /**
   * Get the last update token for messages of a chat room.
   * @param {Object} state the current state.
   * @returns {String}
   */
  getChatRoomMessagesLastUpdateToken: (state) => (chatRoomId) => {
    return getChatRoomMessageToken(state, chatRoomId, 'lastUpdateToken')
  },
  /**
   * Get the next page token for messages of a chat room.
   * @param {Object} state the current state.
   * @returns {String}
   */
  getChatRoomMessagesNextPageToken: (state) => (chatRoomId) => {
    return getChatRoomMessageToken(state, chatRoomId, 'nextPageToken')
  },
  /**
   * Get the top page token for messages of a chat room.
   * @param {Object} state the current state.
   * @returns {String}
   */
  getChatRoomMessagesTopPageToken: (state) => (chatRoomId) => {
    return getChatRoomMessageToken(state, chatRoomId, 'topPageToken')
  },
  /**
   * Get the number of unread messages.
   * @param {Object} state the current state.
   * @returns {Number}
   */
  getUnreadMessagesCount(state) {
    return state.users[state.currentUserId]?.unreadMessagesCount
  },
  /**
   * Whether if all messages in a chat room have been loaded.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isChatRoomMessagesFullyLoaded: (state) => (chatRoomId) => {
    return (
      state.users[state.currentUserId]?.messages[chatRoomId]?.fullyLoaded ||
      false
    )
  },
  /**
   * Whether is chat room state has been initialized for a user.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isUserStateInitialized(state) {
    return Boolean(state.users[state.currentUserId])
  },
}

export const mutations = {
  /**
   * Add chat rooms to the existing chat rooms list.
   * @param {Object} state the current state.
   * @param {Array<Object>|Object} chatRooms the chat rooms to add.
   */
  addChatRooms(state, chatRooms) {
    const playerId = initializeChatRoomState(state)
    state.users[playerId].chatRooms.list = [
      ...(Array.isArray(chatRooms) ? chatRooms : [chatRooms]),
      ...state.users[playerId].chatRooms.list,
    ]
  },
  /**
   * Insert chat rooms in the existing chat rooms list.
   * If a chat room already exists, update it.
   * @param {Object} state the current state.
   * @param {Array<Object>} chatRooms the chat rooms to upsert.
   */
  upsertChatRooms(state, chatRooms) {
    const playerId = initializeChatRoomState(state)
    const newChatRooms = []
    const currentChatRooms = state.users[playerId].chatRooms.list.slice()

    const rooms = Array.isArray(chatRooms)
      ? chatRooms
      : chatRooms
      ? [chatRooms]
      : []
    rooms.forEach((chatRoom) => {
      const index = findIndex(
        currentChatRooms,
        (room) => room.id === chatRoom.id
      )

      if (index > -1) {
        currentChatRooms[index] = chatRoom
      } else {
        newChatRooms.push(chatRoom)
      }
    })

    state.users[playerId].chatRooms.list = [
      ...orderBy(
        [...newChatRooms, ...currentChatRooms],
        [
          (room) =>
            room.last_message?.last_update > room.last_update
              ? room.last_message.last_update
              : room.last_update,
        ],
        ['desc']
      ),
    ]
  },
  /**
   * Remove chat rooms from the existing list.
   * Set one of `chatRooms` or `ids` parameter only.
   * @param {Object} state the current state.
   * @param {Array<Object|Number>} chatRooms the chat rooms to remove.
   * @param {Array<Number>} ids a list of chat rooms to remove.
   */
  removeChatRooms(state, { chatRooms, ids }) {
    const playerId = initializeChatRoomState(state)
    const deleteChatRoomIds = ids || chatRooms.map((room) => room.id)

    state.users[playerId].chatRooms.list = [
      ...state.users[playerId].chatRooms.list.filter(
        (room) => !deleteChatRoomIds.includes(room.id)
      ),
    ]

    if (
      deleteChatRoomIds.includes(state.users[playerId].chatRooms.selectedId)
    ) {
      /**
       * Current selected chat room is being removed, select the next one.
       */
      state.users[playerId].chatRooms.selectedId = undefined
    }
  },
  /**
   * Remove chat rooms without messages.
   * @param {Object} state the current state.
   * @param {Object} filters some filters to select eligible chat rooms.
   */
  removeChatRoomsWithoutMessage(state, filters = {}) {
    const playerId = initializeChatRoomState(state)

    state.users[playerId].chatRooms.list = [
      ...state.users[playerId].chatRooms.list.filter((room) => {
        if (!room.last_message) {
          // check if room is matching filters
          const isMatchingFilters = Object.entries(filters).every(
            ([key, value]) => room[key] === value
          ) // if no filter, `every()` returns `true`

          if (isMatchingFilters) {
            return false
          }
        }

        return true
      }),
    ]
  },
  /**
   * Set the current user ID.
   * @param {Object} state the current state.
   * @param {Number} userId the current user id.
   */
  setCurrentUserId(state, userId) {
    state.currentUserId = userId
  },
  /**
   * Set the list of chat rooms.
   * @param {Object} state the current state.
   * @param {Array<Object>} chatRooms the chat rooms to set.
   */
  setChatRooms(state, chatRooms) {
    const playerId = initializeChatRoomState(state)
    state.users[playerId].chatRooms.list = chatRooms
  },
  /**
   * Set the next page token.
   * @param {Object} state the current state.
   * @param {String} token the new token to set.
   */
  setChatRoomsNextPageToken(state, token) {
    setChatRoomToken(state, 'nextPageToken', token)
  },
  /**
   * Set the top page token.
   * @param {Object} state the current state.
   * @param {String} token the new token to set.
   */
  setChatRoomsTopPageToken(state, token) {
    setChatRoomToken(state, 'topPageToken', token)
  },
  /**
   * Set the current selected chat room.
   * @param {Object} state the current state.
   * @param {Object|Number} chatRoom the current chat room or its ID.
   */
  setSelectedChatRoom(state, chatRoom) {
    const playerId = initializeChatRoomState(state)

    if (typeof chatRoom === 'object') {
      const existing =
        state.users[playerId].chatRooms.list.findIndex(
          (room) => room.id === chatRoom.id
        ) > -1

      if (!existing) {
        /**
         * Chat room is just created,
         * or it exists but not loaded yet,
         * or it's a DM with no message (so not in the list).
         */
        state.users[playerId].chatRooms.list = [
          chatRoom,
          ...state.users[playerId].chatRooms.list,
        ]
      }

      state.users[playerId].chatRooms.selectedId = chatRoom.id
    } else {
      state.users[playerId].chatRooms.selectedId = chatRoom
    }
  },
  /**
   * set the number of unread messages.
   * @param {Object} state the current state.
   * @param {Number} count the unread messages count.
   */
  setUnreadMessagesCount(state, count) {
    const playerId = initializeChatRoomState(state)
    state.users[playerId].unreadMessagesCount = count
  },
  /**
   * Add messages in a chat room.
   * @param {Object} state the current state.
   * @param {Number} chatRoomId the chat room for which to add messages.
   * @param {Array<Object>|Object} messages the messages to add.
   */
  addChatRoomMessages(state, { chatRoomId, messages }) {
    const playerId = initializeChatRoomMessageState(state, chatRoomId)
    state.users[playerId].messages[chatRoomId].list = sortBy(
      [
        ...state.users[playerId].messages[chatRoomId].list,
        ...(Array.isArray(messages) ? messages : [messages]),
      ],
      ['creation_date']
    )
  },
  /**
   * Insert or update messages of a chat room.
   * @param {Object} state the current state.
   * @param {Number} chatRoomId the chat room for which to insert messages.
   * @param {Array<Object>|Object} messages the messages to insert.
   */
  upsertChatRoomsMessages(state, { chatRoomId, messages }) {
    const playerId = initializeChatRoomMessageState(state, chatRoomId)

    const newMessages = []
    const currentMessages =
      state.users[playerId].messages[chatRoomId].list.slice()

    messages.forEach((message) => {
      const index = findIndex(currentMessages, {
        message_uuid: message.message_uuid,
      })

      if (index > -1) {
        currentMessages[index] = { ...message }
      } else {
        newMessages.push(message)
      }
    })

    state.users[playerId].messages[chatRoomId].list = sortBy(
      [...currentMessages, ...newMessages],
      ['creation_date']
    )

    // check if a message is more recent than the room last message
    if (state.users[playerId].messages[chatRoomId].list.length) {
      const lastMessage = Object.assign(
        {},
        state.users[playerId].messages[chatRoomId].list[
          state.users[playerId].messages[chatRoomId].list.length - 1
        ]
      )
      const chatRoom = state.users[state.currentUserId]?.chatRooms.list.find(
        (room) => room.id === chatRoomId
      )

      if (
        lastMessage &&
        chatRoom &&
        chatRoom.last_message &&
        (lastMessage.creation_date > chatRoom.last_message.creation_date ||
          lastMessage.message_uuid === chatRoom.last_message.message_uuid)
      ) {
        this.commit('chatRooms/upsertChatRooms', {
          ...chatRoom,
          last_message: lastMessage,
        })
      }
    }
  },
  /**
   * Set a list of messages for a chat room.
   * @param {Object} state the current state.
   * @param {Number} chatRoomId the chat room for which to set messages.
   * @param {Array<Object>|Object} messages the messages to set.
   */
  setChatRoomMessages(state, { chatRoomId, messages }) {
    const playerId = initializeChatRoomMessageState(state, chatRoomId)
    state.users[playerId].messages[chatRoomId].list = sortBy(messages, [
      'creation_date',
    ])
  },
  /**
   * Set the last update token for a chat room.
   * @param {Object} state the current state.
   * @param {Number} chatRoomId the chat room for which to set the token.
   * @param {String} token the token to set.
   */
  setChatRoomMessagesLastUpdateToken(state, { chatRoomId, token }) {
    setChatRoomMessageToken(state, chatRoomId, 'lastUpdateToken', token)
  },
  /**
   * Set the next page token for a chat room.
   * @param {Object} state the current state.
   * @param {Number} chatRoomId the chat room for which to set the token.
   * @param {String} token the token to set.
   */
  setChatRoomMessagesNextPageToken(state, { chatRoomId, token }) {
    setChatRoomMessageToken(state, chatRoomId, 'nextPageToken', token)
    setChatRoomMessagesFullyLoaded(state, chatRoomId, token)
  },
  /**
   * Set the top page token for a chat room.
   * @param {Object} state the current state.
   * @param {Number} chatRoomId the chat room for which to set the token.
   * @param {String} token the token to set.
   */
  setChatRoomMessagesTopPageToken(state, { chatRoomId, token }) {
    setChatRoomMessageToken(state, chatRoomId, 'topPageToken', token)
  },
}
