
import AppLayout from '~/components/layouts/AppLayout.vue'

export default {
  name: 'TwoColumns',

  components: {
    AppLayout,
  },
}
