import { request } from '~/utils/request'
import { PLAYER_ROLES_ID } from '~/models/enums'

/* eslint-disable camelcase */

/**
 * Remove values that should not be sent to the server when updating player team info of membership.
 * @param {Object} data the player data.
 * @returns {Object}
 */
const sanitizePlayerTeaminfo = ({
  jersey_number,
  jersey_name,
  surname,
  other_information,
  licence_number,
  sport_kit_jersey_size_id,
  sport_kit_shoe_size_id,
  sport_kit_short_size_id,
  sport_position_id,
  laterality_id,
}) => ({
  jersey_number: parseInt(jersey_number) || -1,
  jersey_name: jersey_name || '',
  surname: surname || '',
  other_information: other_information || '',
  licence_number: licence_number || '',
  sport_kit_jersey_size_id,
  sport_kit_shoe_size_id,
  sport_kit_short_size_id,
  sport_position_id,
  laterality_id: parseInt(laterality_id),
})

/**
 * Remove values that should not be sent to the server when updating membership.
 * @param {Object} data the membership data.
 * @returns {Object}
 */
const sanitizeMembership = ({
  team_id,
  player_id,
  is_membership_validated,
  role_id,
}) => ({
  team_id: parseInt(team_id),
  player_id: parseInt(player_id),
  is_admin: role_id === PLAYER_ROLES_ID.ADMIN,
  is_guest: role_id === PLAYER_ROLES_ID.GUEST,
  is_membership_validated,
})

/* eslint-enable camelcase */

export const actions = {
  /**
   * Create many memberships at once.
   * @param {Number} clubId the club identifier.
   * @param {Array<Object>} memberships the memberships to create.
   * @param {Number} teamId the team identifier.
   * @returns {Promise<Array<Object>>} the created memberships.
   */
  async bulkCreate(_, { clubId, memberships, teamId }) {
    const { data } = await request(this).post(
      '/clubs/{clubId}/teams/{teamId}/members/bulk-create',
      { clubId, teamId },
      memberships
    )

    return data
  },

  /**
   * Delete many memberships.
   * @param {Array<Number>} ids the identifiers of the memberships to delete.
   * @returns {Promise<Object>}
   */
  async bulkDelete(_, { ids }) {
    const { data } = await request(this).post(
      '/memberships/bulk-delete',
      null,
      {
        memberships_ids: ids,
      }
    )

    return data
  },

  /**
   * Delete a membership.
   * @param {Number} membershipId the identifier of the membership to delete.
   * @returns {Promise}
   */
  async delete(_, membershipId) {
    const { data } = await request(this).delete('/memberships/{membershipId}', {
      membershipId,
    })

    return data
  },

  /**
   * Transfer some memberships to another team.
   * @param {Number} clubId the club identifier.
   * @param {Array<Number>} membershipsIds the memberships identifiers.
   * @param {Number} teamId the team identifier.
   * @returns {Promise<Array<Object>>} the new memberships.
   */
  async transfer(_, { clubId, membershipsIds, teamId }) {
    const { data } = await request(this).post(
      '/clubs/{clubId}/teams/{teamId}/members/transfer',
      {
        clubId,
        teamId,
      },
      membershipsIds
    )

    return data
  },

  /**
   * Update a membership.
   * @param {Object} membership the membership values.
   * @param {Number} membershipId the membership unique identifier.
   * @returns {Promise<Object>} the updated membership.
   */
  async updateMembership(_, { id, ...membership }) {
    const { data } = await request(this).put(
      '/memberships/{membershipId}',
      { membershipId: id },
      sanitizeMembership(membership)
    )
    return data
  },

  /**
   * Update player team info.
   * @param {Object} values the player team info.
   * @param {Number} membershipId the membership unique identifier.
   * @returns {Promise<Object>} the updated player team info.
   */
  async updatePlayerTeamInfo(_, { values, membershipId }) {
    const { data } = await request(this).put(
      '/memberships/{membershipId}/player-team-info',
      {
        membershipId,
      },
      sanitizePlayerTeaminfo(values)
    )
    return data
  },
}
