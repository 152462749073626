
import { getMessageFromErrorResponse } from '~/utils/data'

export default {
  props: {
    defaultValue: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      form: {
        fields: {
          clubCode: this.defaultValue,
        },
      },
      club: null,
      loading: false,
    }
  },

  watch: {
    /**
     * Clear form when dialog is not open.
     * @param {Boolean} val the dialog state.
     */
    open(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },

    'form.fields.clubCode'() {
      this.$refs.form.reset()
    },
  },

  methods: {
    /**
     * Try to find club by code.
     */
    async handleSubmit() {
      this.loading = true
      try {
        const res = await this.$store.dispatch('clubs/fetchOneByCode', {
          code: this.form.fields.clubCode,
        })

        this.$emit('submit', res)
      } catch (error) {
        let message
        if (error.response?.status === 404 && this.$refs.form) {
          this.$refs.form.setErrors({
            clubCode: this.$t('error.club.not_found'),
          })
        } else {
          message = getMessageFromErrorResponse(error)
        }

        if (message) {
          this.$notify({ message, type: 'error' })
        }
      } finally {
        this.loading = false
      }
    },
  },
}
