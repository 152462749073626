export default function ({ $axios, redirect }) {
  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    const isAuthenticated = error.config?.headers?.Authorization

    if (code === 403 && isAuthenticated) {
      redirect({
        name: 'clubs-denied',
      })
      return Promise.resolve(false)
    }
    if (code === 503) {
      redirect({
        name: 'maintenance',
      })
      return Promise.resolve(false)
    }
  })
}
