
export default {
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    club() {
      return this.$store.getters['clubs/getSelected']
    },

    route() {
      return this.club
        ? {
            name: 'clubs-club_id-account',
            params: { ...this.$route.params, club_id: this.club.id },
          }
        : {
            name: 'account',
          }
    },
  },
}
