
export default {
  emits: ['confirm', 'input'],

  props: {
    value: {
      type: Boolean,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    open: {
      get() {
        return typeof this.value === 'boolean' ? this.value : this.isOpen
      },
      set(val) {
        this.isOpen = val
        this.$emit('input', val)
      },
    },
  },

  methods: {
    handleCancel() {
      this.open = false
    },

    handleConfirm() {
      this.open = false
      this.$emit('confirm')
    },
  },
}
