
import ClubAddressFormStep from './steps/ClubAddressFormStep.vue'
import PlayerProfileFormStep from './steps/PlayerProfileFormStep.vue'
import { ADMIN_STATUS } from '~/models/enums'
import { isEmailUpdatable } from '~/models/player'
import { isFrenchTimezoneId } from '~/utils/data'

export default {
  fetch() {
    return this.fetchCompletedAnnouncements()
  },

  data() {
    // cannot use computed properties because not defined yet when initializing data
    const user = this.$auth.user
    const club = this.$store.getters['clubs/getSelected']
    const isFrenchTimezone = club && isFrenchTimezoneId(club.timezone_id)

    return {
      currentStepUuid: null,
      open: false,
      loading: false,
      seenStepUuids: [],
      stepsToDisplay: [],
      steps: [
        /**
         * Possible options:
         * @param {String} uuid the component unique identifier
         * @param {Boolean} canMarkCompleted whether the component can be marked as completed (default: false).
         * You should set false for conditional steps based on some db data.
         * @param {Element} component the component to display
         * @param {Function} condition a callback to know if the step should be displayed (optional)
         * @param {Boolean} hideActions whether to hide the actions buttons (optional)
         * @param {String} maxWidth the dialog size (optional). Can be 'lg', 'md', 'sm', 'xl', 'xs' or `null` (full size).
         * @param {Boolean} required whether if the step can be skipped or not (default: false)
         * @param {String} subtitle a subtitle to display in the dialog (optional)
         * @param {String} title a title to display in the dialog (optional)
         *
         * When you delete a step (component removed), please write also a migration to remove
         * the component UUID from the database.
         */
        {
          uuid: '4ab3373a-2f34-44a4-b3af-953e76aa177a',
          canMarkRead: false,
          component: ClubAddressFormStep,
          condition:
            isFrenchTimezone &&
            club &&
            club.membership?.admin_status === ADMIN_STATUS.APPROVED &&
            (!club.town_id || !club.street_address),
          hideActions: true,
          required: true,
          subtitle: this.$t(
            'page.club_player_announcement.club_address.subtitle'
          ),
          title: this.$t('page.club_player_announcement.club_address.title'),
        },
        {
          uuid: 'ecbf2fb4-5971-41a0-bfc3-4091bc2dc377',
          canMarkRead: false,
          component: PlayerProfileFormStep,
          condition: () => {
            if (!user) {
              return false
            }

            let missingEmail = !user.email
            const missingPhone = !user.phone_number

            if (missingEmail && !isEmailUpdatable(user)) {
              // if apple or facebook, don't prompt for email because the server will reject the request
              missingEmail = false
            }

            return missingPhone || missingEmail
          },
          hideActions: true,
          required: true,
          subtitle: this.$t(
            'page.club_player_announcement.player_profile.subtitle'
          ),
          title: this.$t('phrase.profile'),
        },
        // {
        //   uuid: 'a77dccff-6665-42fb-bfb1-5ad679653d10',
        //   canMarkRead: true,
        //   component: WelcomeStep,
        // },
      ],
    }
  },

  computed: {
    /**
     * The current selected club.
     * @returns {Object}
     */
    club() {
      return this.$store.getters['clubs/getSelected']
    },

    /**
     * Whether if the current selected timezone is the french one.
     * @returns {Boolean}
     */
    isFrenchTimezone() {
      return this.club && isFrenchTimezoneId(this.club.timezone_id)
    },

    /**
     * @returns {Boolean}
     */
    isPersistent() {
      return this.stepsToDisplay.some((step) => step.required)
    },

    /**
     * @returns {Number}
     */
    currentStepIndex() {
      return this.currentStepUuid
        ? this.stepsToDisplay.findIndex(
            (step) => this.currentStepUuid === step.uuid
          )
        : -1
    },

    /**
     * @returns {Object}
     */
    currentStep() {
      // if no step at index, javascript instruction will return undefined (and will not raise warning)
      return this.stepsToDisplay[this.currentStepIndex]
    },

    /**
     * @returns {Object}
     */
    nextStep() {
      // if no step at index+1, javascript instruction will return undefined (and will not raise warning)
      return this.stepsToDisplay[this.currentStepIndex + 1]
    },

    /**
     * @returns {Object}
     */
    previousStep() {
      // if no step at index-1, javascript instruction will return undefined (and will not raise warning)
      return this.stepsToDisplay[this.currentStepIndex - 1]
    },

    /**
     * The steps that should be marked as completed when closing the dialog.
     * @returns {Array<String>}
     */
    stepsToMarkCompleted() {
      const uuids = this.stepsToDisplay
        .filter(
          (step) => step.canMarkRead && !this.seenStepUuids.includes(step.uuid)
        )
        .map((step) => step.uuid)

      return uuids
    },

    /**
     * The current authenticated user.
     * @returns {Object}
     */
    user() {
      return this.$auth.user
    },
  },

  watch: {
    /**
     * Mark steps as completed when dialog closes.
     * @param {Boolean} val whether if the dialog is open or not.
     */
    async open(val) {
      if (!val) {
        // mark steps completed when dialog closes
        await this.markAsRead()
      }
    },
    /**
     * Open the dialog when some steps are displayable.
     * @param {Array} val
     * @param {Array} oldVal
     */
    stepsToDisplay(val, oldVal) {
      if (val.length !== oldVal.length) {
        this.openDialog()
      }
    },
  },

  methods: {
    /**
     * Populate steps to display in component data.
     * @param {boolean} [showOptionalSteps=true] whether to display the optional steps.
     * The optional steps should not be displayed if the request to get seen steps failed.
     */
    computeStepsToDisplay(showOptionalSteps = true) {
      this.stepsToDisplay = this.steps.filter((step) => {
        // error when loading the seen steps?
        if (!step.required && !showOptionalSteps) {
          return false
        }

        // step already seen?
        if (this.seenStepUuids.includes(step.uuid)) {
          return false
        }

        switch (typeof step.condition) {
          case 'undefined':
            return true
          case 'function':
            return step.condition()
          default:
            return step.condition
        }
      })
    },

    /**
     * Fetch announcements the current user already reviewed.
     */
    async fetchCompletedAnnouncements() {
      if (this.club?.membership?.admin_status !== ADMIN_STATUS.APPROVED) {
        // user has no club or membership is pending
        this.computeStepsToDisplay(false)
        return
      }

      try {
        this.loading = true

        const clubPlayerAnnouncements = await this.$store.dispatch(
          'announcements/fetchClubPlayerAnnouncementList',
          { clubId: this.club.id }
        )
        this.seenStepUuids = (clubPlayerAnnouncements || []).map(
          (announcement) => announcement.component_uuid
        )

        this.computeStepsToDisplay(true)
      } catch (err) {
        /**
         * Don't notify user about the error, but report it to Sentry.
         */
        console.error(err)

        this.computeStepsToDisplay(false)
      } finally {
        this.loading = false
      }
    },

    /**
     * Close the dialog.
     */
    handleClose() {
      this.open = false
    },

    /**
     * Go to the next step.
     * If the last step is reached, close the dialog.
     */
    handleNext() {
      if (this.nextStep) {
        this.currentStepUuid = this.nextStep.uuid
      } else {
        this.handleClose()
      }
    },

    /**
     * Go to the previous step if possible.
     */
    handlePrevious() {
      if (this.previousStep) {
        this.currentStepUuid = this.previousStep?.uuid
      }
    },

    /**
     * Set the current step required.
     * @param {Object} payload
     */
    handleSetOptions(payload) {
      if (this.currentStepUuid) {
        const steps = this.steps.slice()
        const indexInStepsDisplayed = steps.findIndex(
          (step) => step.uuid === this.currentStepUuid
        )

        steps.splice(indexInStepsDisplayed, 1, {
          ...this.currentStep,
          ...payload,
        })
        this.steps = steps

        this.computeStepsToDisplay()
      }
    },

    /**
     * Mark announcements read.
     */
    async markAsRead() {
      const uuids = this.stepsToMarkCompleted

      if (!uuids.length || this.loading) {
        return
      }

      this.loading = true

      try {
        await this.$store.dispatch('announcements/markAnnouncementCompleted', {
          clubId: this.club.id,
          uuids,
        })
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    /**
     * Open the dialog if needed.
     */
    openDialog() {
      if (this.stepsToDisplay.length) {
        this.currentStepUuid = this.stepsToDisplay[0].uuid
        this.open = true
      }
    },
  },
}
