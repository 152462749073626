
import ClubPicture from '~/components/molecules/clubs/ClubPicture.vue'
export default {
  components: { ClubPicture },
  computed: {
    club() {
      return this.$store.getters['clubs/getSelected'] || {}
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
}
