
import HeaderPaymentClub from '~/components/molecules/header/HeaderPaymentClub'
import HeaderUserAccount from '~/components/molecules/header/HeaderUserAccount'

export default {
  components: { HeaderPaymentClub, HeaderUserAccount },

  props: {
    fluid: {
      type: Boolean,
      default: false,
    },

    showMenu: {
      type: Boolean,
      default: false,
    },

    showPaymentClub: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name
    },

    club() {
      return this.$store.getters['clubs/getSelected']
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    isOpen() {
      return this.$store.getters['app/isSidebarOpen']
    },
  },

  methods: {
    handleClick() {
      this.$store.commit('app/setSidebarOpen', !this.isOpen)
    },
  },
}
