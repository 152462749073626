export const CHAT_MESSAGE_TYPES = Object.freeze({
  TEXT_MESSAGE: 1,
  SYSTEM_MESSAGE: 2,
  REPLY_TO: 3,
  IMAGE_MESSAGE: 4,
  DOCUMENT_MESSAGE: 5,
})

export const CHAT_SYSTEM_MESSAGE_TYPES = Object.freeze({
  PLAYER_LEAVE_CHAT_ROOM: 1,
  ADD_PLAYERS_TO_CHAT_ROOM: 2,
  PLAYER_GRANT_ADMIN_RIGHTS: 3,
  PLAYER_REMOVE_ADMIN_RIGHTS: 4,
  PLAYER_CREATE_CHAT_ROOM: 5,
  PLAYER_RENAME_CHAT_ROOM: 6,
  DELETE_PLAYER_FROM_CHAT_ROOM: 7,
})
