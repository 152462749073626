
export default {
  emits: ['input'],

  props: {
    color: {
      type: String,
      default: undefined,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: undefined,
    },
    initialPicker: {
      type: String,
      default: null,
      validator: (val) => ['DATE', 'MONTH', 'YEAR'].includes(val),
    },
    min: {
      type: String,
      default: undefined,
    },
    max: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    showAdjacentMonths: {
      type: Boolean,
      default: true,
    },
    showCurrent: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activePicker: this.initialPicker,
      date: this.value || null,
      dateFormatted: null,
      rulesErrorMessages: [],
      open: false,
    }
  },

  computed: {
    allErrorMessages() {
      return [
        ...(Array.isArray(this.errorMessages)
          ? this.errorMessages
          : [this.errorMessages]),
        ...this.rulesErrorMessages,
      ].filter(Boolean)
    },

    computedDateFormatted: {
      get() {
        return this.formatDate(this.date)
      },
      set(val) {
        this.date = val
      },
    },

    firstDayOfWeek() {
      return this.$dayjs.localeData().firstDayOfWeek()
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val)
      this.$emit('input', val)

      // validate rules with unformatted value
      if (Array.isArray(this.rules)) {
        this.rulesErrorMessages = this.rules
          .map((rule) => rule(val))
          .filter((val) => typeof val === 'string')
      }
    },

    open: {
      immediate: true,
      handler(val) {
        if (val && this.initialPicker) {
          this.$nextTick(() => {
            // in component doc, there is a setTimeout(()=>{})
            this.activePicker = this.initialPicker
          })
        }
      },
    },
  },

  methods: {
    formatDate(date) {
      if (!date) {
        return null
      }

      return this.$dayjs(date).format('L')
    },
  },
}
