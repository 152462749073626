import NotificationModule from './NotificationModule.vue'
import { events } from './events'

export const install = (Vue) => {
  Vue.prototype.$notify = (props, options) => {
    events.$emit('notify', props)
  }

  Vue.component('NotificationModule', NotificationModule)
}

export default { install, events }
