
import { buildPictureUrl, buildUserFullName } from '~/utils/data'

export default {
  props: {
    component: {
      type: String,
      default: 'div',
    },
  },

  computed: {
    profilePicture() {
      return buildPictureUrl(this.user.profile_pic_url, this.user.updated_at)
    },

    username() {
      return buildUserFullName(this.user)
    },

    user() {
      return this.$auth.user
    },
  },
}
