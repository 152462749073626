
import { VBtn } from 'vuetify/lib/components'

export default {
  extends: VBtn,
  props: {
    elevation: {
      type: [Number, String],
      default: undefined,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    allProps() {
      return {
        ...this.$attrs,
        ...this._props,
        elevation: this.computedElevation,
      }
    },
    /**
     * Default elevation is 2, we want default 1.
     */
    computedElevation() {
      return this.isElevated && this.color !== 'teampulse'
        ? this.elevation || 1
        : 0
    },
  },
}
