
export default {
  props: {
    areas: {
      type: [String, Array],
      default: null,
    },
    xs: {
      type: [Number, String],
      default: 1,
    },
    sm: {
      type: [Number, String],
      default: 2,
    },
    md: {
      type: [Number, String],
      default: 3,
    },
    lg: {
      type: [Number, String],
      default: 4,
    },
    xl: {
      type: [Number, String],
      default: 4,
    },
  },

  computed: {
    /**
     * Get grid template depending on the screen size.
     */
    customGridCss() {
      const nbPerRow = this[this.$vuetify.breakpoint.name] // get prop value for the current breakpoint

      let gridTemplateAreas
      if (this.areas) {
        const isArray = Array.isArray(this.areas)
        const isArrayOfArray =
          isArray && this.areas.length && Array.isArray(this.areas[0])

        gridTemplateAreas = isArrayOfArray
          ? this.areas.map((line) => `"${line.join(' ')}"`).join(' ')
          : isArray
          ? `"${this.areas.join(' ')}"`
          : this.areas
      }

      return {
        gridTemplateColumns: `repeat(${nbPerRow}, 1fr)`,
        gridTemplateAreas,
      }
    },
  },
}
