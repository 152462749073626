
import { ADMIN_STATUS, CLUB_LEGAL_STATUS } from '~/models/enums'
import { isAntiAdsAvailable } from '~/utils/data'

export default {
  computed: {
    /**
     * The current route.
     * @returns {String}
     */
    currentRouteName() {
      return this.$route.name
    },
    /**
     * The current selected club.
     * @returns {Object}
     */
    club() {
      return this.$store.getters['clubs/getSelected']
    },
    /**
     * The id of club selected.
     * @returns {Number}
     */
    clubId() {
      return this.club?.id
    },
    itemPosts() {
      return {
        id: 'posts',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-vestiaire-ico.svg',
        icon_active: '/svg/ico-bottombar-vestiaire-ico-orange.svg',
        label: this.$t('page.menu.posts'),
        route: {
          name: 'clubs-club_id-posts',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemEvents() {
      return {
        id: 'events',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-event-ico.svg',
        icon_active: '/svg/ico-bottombar-event-ico-orange.svg',
        label: this.$t('phrase.title_events'),
        route: {
          name: 'clubs-club_id-events',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemMembers() {
      return {
        id: 'members',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-members-ico.svg',
        icon_active: '/svg/ico-bottombar-members-ico-orange.svg',
        label: this.$t('phrase.members'),
        route: {
          name: 'clubs-club_id-players',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemTeams() {
      return {
        id: 'teams',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-team-ico.svg',
        icon_active: '/svg/ico-bottombar-team-ico-orange.svg',
        label: this.$t('phrase.nav_teams'),
        route: {
          name: 'clubs-club_id-teams',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemMessaging() {
      return {
        id: 'messaging',
        badge: this.$store.getters['chatRooms/getUnreadMessagesCount'],
        disabled: false,
        icon: '/svg/ico-bottombar-chat-ico.svg',
        icon_active: '/svg/ico-bottombar-chat-ico-orange.svg',
        label: this.$t('page.menu.messaging.main'),
        route: this.clubId
          ? {
              name: 'clubs-club_id-messaging',
              params: { ...this.$route.params, club_id: this.clubId },
            }
          : {
              name: 'clubs-messaging',
              params: { ...this.$route.params },
            },
        // TODO: need a chat api update before listing archived rooms
        // @see https://app.clickup.com/t/860ptkhgf
        // children: [
        //   {
        //     id: 'messaging-active',
        //     exact: true,
        //     label: this.$t('phrase.title_chat_list'),
        //     route: this.clubId
        //       ? {
        //           name: 'clubs-club_id-messaging',
        //           params: { club_id: this.clubId },
        //         }
        //       : {
        //           name: 'clubs-messaging',
        //           params: { ...this.$route.params },
        //         },
        //   },
        //   {
        //     id: 'messaging-archived',
        //     exact: true,
        //     label: this.$t('page.menu.messaging.archived'),
        //     route: this.clubId
        //       ? {
        //           name: 'clubs-club_id-messaging-archived',
        //           params: { club_id: this.clubId },
        //         }
        //       : {
        //           name: 'clubs-messaging-archived',
        //           params: { ...this.$route.params },
        //         },
        //   },
        // ],
      }
    },
    itemPayments() {
      return {
        id: 'payments',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-payments-ico.svg',
        icon_active: '/svg/ico-bottombar-payments-ico-orange.svg',
        label: this.$t('phrase.nav_payments'),
        route: {
          name: 'clubs-club_id-payments',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemAccounting() {
      return {
        id: 'accounting',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-accounting-ico.svg',
        icon_active: '/svg/ico-bottombar-accounting-ico-orange.svg',
        label: this.$t('page.menu.accounting'),
        route: {
          name: 'clubs-club_id-accounting',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemAntiAds() {
      return {
        id: 'anti-ads',
        disabled: !this.isClubMembershipValidated,
        icon: '/svg/ico-bottombar-ads-ico.svg',
        icon_active: '/svg/ico-bottombar-ads-ico-orange.svg',
        label: this.$t('page.menu.remove_ads'),
        route: {
          name: 'clubs-club_id-settings-subscription',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    itemShop() {
      return {
        id: 'teampulse-shop',
        disabled: false,
        href: 'https://teampulseshop.com/',
        icon: '/svg/ico-bottombar-shop-ico.svg',
        icon_active: '/svg/ico-bottombar-shop-ico-orange.svg',
        label: this.$t('page.menu.teampulse_shop'),
        target: '_blank',
      }
    },
    itemSettings() {
      return {
        id: 'settings',
        disabled: !this.isClubMembershipValidated,
        exact:
          this.currentRouteName === 'clubs-club_id-settings-subscription' &&
          this.showAntiAdsMenu,
        icon: '/svg/ico-bottombar-settings-ico.svg',
        icon_active: '/svg/ico-bottombar-settings-ico-orange.svg',
        label: this.$t('page.menu.settings'),
        route: {
          name: 'clubs-club_id-settings',
          params: { ...this.$route.params, club_id: this.clubId },
        },
      }
    },
    /**
     * The menu items.
     * @returns {Array}
     */
    items() {
      const items = [
        this.isClubMembershipValidated && this.itemPosts,
        this.isClubMembershipValidated && this.itemEvents,
        this.isClubMembershipValidated && this.itemMembers,
        this.isClubMembershipValidated && this.itemTeams,
        this.itemMessaging,
        this.isClubMembershipValidated && this.itemPayments,
        this.isClubMembershipValidated && this.itemAccounting,
        this.isClubMembershipValidated &&
          this.showAntiAdsMenu &&
          this.itemAntiAds,
        this.itemShop,
        this.isClubMembershipValidated && this.itemSettings,
      ]

      return items.filter(Boolean).map((item) => ({
        ...item,
        active: item.exact
          ? this.currentRouteName === item.route?.name
          : this.currentRouteName.startsWith(item.route?.name),
        ...(Array.isArray(item.children)
          ? {
              children: item.children.map((child) => ({
                ...child,
                active: child.exact
                  ? this.currentRouteName === child.route?.name
                  : this.currentRouteName.startsWith(child.route?.name),
              })),
            }
          : {}),
      }))
    },

    /**
     * Whether if club timezone is Europe/Paris.
     * @returns {Boolean}
     */
    isAntiAdsAvailable() {
      return isAntiAdsAvailable(this.club, this.$config)
    },

    /**
     * Whether if the legal status of the club is "business".
     * @returns {Boolean}
     */
    isClubBusiness() {
      return this.club?.legal_status === CLUB_LEGAL_STATUS.BUSINESS
    },

    /**
     * Whether if the user is a validated member of the current club.
     * @returns {Boolean}
     */
    isClubMembershipValidated() {
      return this.club?.membership?.admin_status === ADMIN_STATUS.APPROVED
    },

    /**
     * Whether to show the anti-ads menu item.
     * @returns {Boolean}
     */
    showAntiAdsMenu() {
      return (
        this.isAntiAdsAvailable &&
        !(this.club?.anti_ads_activated && this.club?.anti_ads_validity)
      )
    },
  },

  render() {
    return this.$scopedSlots.default({
      items: this.items,
      itemAccounting: this.itemAccounting,
      itemEvents: this.itemEvents,
      itemMessaging: this.itemMessaging,
      itemPayments: this.itemPayments,
      itemMembers: this.itemMembers,
      itemPosts: this.itemPosts,
      itemTeams: this.itemTeams,
    })
  },
}
