import { request } from '~/utils/request'

export const actions = {
  /**
   * Whether if the authentication with facebook is available.
   * @returns {Promise<Boolean>}
   */
  async isFacebookAuthAllowed() {
    const { data } = await request(this).get(
      '/settings/is-facebook-auth-allowed'
    )
    return data.is_facebook_auth_allowed
  },

  /**
   * Send reset password request.
   * @param {Object} _ the current app context.
   * @param {String} email the email for which to recover password.
   * @returns {Promise}
   */
  sendResetPassword(_, { email }) {
    return request(this).post('/accounts/reset-password', null, { email })
  },

  /**
   * Create a new user account.
   * @param {Object} _ the current app context.
   * @param {Object} values the data to send to the server.
   * @returns {Promise}
   */
  signup(
    _,
    /* eslint-disable camelcase */
    {
      first_name,
      last_name,
      email,
      password,
      is_allowing_newsletter,
      fcm_token,
      locale_id,
      reset_connections,
      phone_number,
    } /* eslint-enable camelcase */
  ) {
    return request(this).post('/accounts/signup', null, {
      // required
      first_name,
      last_name,
      email,
      password,
      // optional
      is_allowing_newsletter,
      fcm_token,
      locale_id,
      reset_connections,
      phone_number,
    })
  },

  /**
   * Reset the password of a user.
   * @param {String} email the user email.
   * @param {String} oldPassword the current user password.
   * @param {String} newPassword the new password to set.
   * @returns {Promise<Object>} the new user credentials.
   */
  async updatePassword(_, { email, oldPassword, newPassword }) {
    const { data } = await request(this).post('/accounts/password', null, {
      email,
      old_password: oldPassword,
      new_password: newPassword,
    })

    return data
  },
}
