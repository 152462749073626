// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/poppins_regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/poppins_medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/poppins_semi_bold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/poppins_bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Poppins\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:500;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}@font-face{font-family:\"Poppins\";font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}:root{--white:#fff;--black:#000;--teampulse-gradient:linear-gradient(90deg,var(--v-neon-red-base) 0%,var(--v-flamenco-orange-base) 70%);--i-os-system-fills-light-3-tertiary-fill-color:hsla(240,4%,48%,.12);--i-os-system-fills-light-2-secondary-fill-color:hsla(240,3%,49%,.16);--v-primary-faded:#f9e9dd;--card-disabled-opacity:0.6}.fill-height{height:100%}.fill-width{width:100%}.theme--light.v-card>.v-card__actions>.v-btn--disabled{color:rgba(0,0,0,.26)!important}.v-data-table.clickable>.v-data-table__wrapper tbody tr:hover{cursor:pointer}.text-pre-line{white-space:pre-line}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
